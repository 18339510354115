import React, { Component } from "react";
import StandardPage from "../Components/Layout/Dashboard/StandardPage";
import UpdateProfile from "../Components/Profile/UpdateProfile";

class ProfilePage extends Component {
  render() {
    return <StandardPage title="Mon profil" component={<UpdateProfile/>} />;
  }
}

export default ProfilePage;
