import { apiClient} from "./AxiosService"

class StoryService {

    // Get current user's stories
    static async get() {
        return apiClient
            .get("story/")
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error;
            });
    }

    static async retrieve(id) {
        return apiClient
            .get(`story/${id}/`)
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error;
            });
    }

    static async create(name) {
        return apiClient
            .post(
                "story/",
                { name }
            )
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error;
            });
    }

    static async delete(id) {
        
        return apiClient
            .delete(`story/${id}/`)
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error;
            });
    }

    static async publish(id, published) {

        return apiClient
            .put(`story/${id}/`,
            { published }
            ).then(response => {
                return response.data
            })
            .catch(error => {
                throw error;
            });
    }

    static async updateVersion(id, version) {

        return apiClient
            .put(`story/${id}/`,
            { version })
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error;
            });
    }

    static async rename(id, name) {

        return apiClient
            .put(`story/${id}/`,
            { name })
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error;
            });
    }

}

export default StoryService;
