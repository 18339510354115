import { apiClient} from "./AxiosService"
import axios from 'axios'
import { Cookies } from "react-cookie";

class ChapterService {

    static async retrieve(id) {
        return apiClient
            .get(`chapter/${id}/`)
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error
            });
    }

    static async getByTimeline(timelineId){
        return apiClient
            .get(`chapter?timeline=${timelineId}`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error
            });
    }

    static async create(timeline, name, order) {
        return apiClient
            .post("chapter/",
                { name, order, timeline },
            )
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error
            });
    }

    static async saveJSON(id, json) {
        return apiClient
            .patch(
                `chapter/${id}/`,
                { json },
            )
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error
            });
    }

    static async delete(id) {
        
        return apiClient
            .delete(`chapter/${id}/`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error
            });
    }

    static async publish(id, published) {

        return apiClient
            .put(`chapter/${id}/`,
            { published }
            ).then(response => {
                return response.data
            })
            .catch(error => {
                throw error;
            });
    }

    static async rename(id, name) {

        return apiClient
            .put(`chapter/${id}/`,
            { name })
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error;
            });
    }

    static async changeImage(id, image) {

        const formData = new FormData();
        formData.append('image',image)

        let cookie = new Cookies();
        let token = cookie.get("jwt");
        let tmpApiClient = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `JWT ${token}`
            }
        })

        return tmpApiClient
            .put(`chapter/${id}/`,
             formData )
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error;
            });
    }

}

export default ChapterService;
