import React, { Component } from "react";
import StandardPage from "../Components/Layout/Dashboard/StandardPage";
import ViewStory from "../Components/Account/ViewStory";

class Account extends Component {
  render() {
    return <StandardPage component={<ViewStory id={this.props.match.params.id} story={this.props.location.state.story} />} />;
  }
}

export default Account;
