import React, { Component } from "react";
import Loading from "../Layout/Loading";
import { Redirect, Link } from "react-router-dom";
import AxiosService from "../../Api/AxiosService";
import { Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Col, Card, Row, Media, Badge } from 'reactstrap';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
dayjs.locale('fr')

class ViewTimeline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: {
        path: "",
        props: {}
      },
      story: null,
      timeline: null,
      deleteForm: ""
    };
  }

  render() {
    if (this.state.redirect.path !== "") {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect.path,
            state: { from: this.state.redirect.props }
          }}
        />
      );
    }
    if (this.state.story != null) {
      return (
        <Row>
          <Col>
            <Card>
              <div className="border-0 card-header">
                <div className="align-items-center row">
                  <div className="col">
                    <h3 className="mb-0">
                      Chapitres de <span title="Modifier le nom de la timeline" onClick={() => this.handleChangeTimelineName(this.state.timeline.pk, this.state.story.pk, this.state.story.version + 1)} style={{ cursor: 'pointer', borderBottom: '1px dotted #000' }}>{this.state.timeline.name}</span>
                    </h3>
                  </div>
                  <div className="col text-right">
                    <span onClick={() => this.handleCreateChapter(this.state.timeline.pk)} className="btn btn-primary">Créer un chapitre</span>
                  </div>
                </div>
              </div>

              <Table
                className="align-items-center table"
                responsive
              >
                <thead className="thead">
                  <tr>
                    <th scope="col">Titre</th>
                    <th scope="col">Layers</th>
                    <th scope="col">Dernière modification</th>
                    <th scope="col">Publié</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (this.state.timeline.chapters !== undefined && this.state.timeline.chapters.length > 0) ? this.state.timeline.chapters.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td >
                            <Link to={{
                              pathname: `/editor/chapter/${item.pk}/`,
                              state: {
                                story: item,
                              }
                            }}
                              style={{ display: 'flex', flexDirection: 'row' }}>
                              <Media className="align-items-center">
                                <img
                                  style={{ maxWidth: "100%", maxHeight: "40px" }}
                                  src={item.image !== null ? AxiosService.media.getMediaUrl(item.image) : require("../../assets/home/img/icon-placeholder.png")}
                                  key={item.id}
                                  alt="chapter"
                                  className="avatar rounded mr-3"
                                  crossOrigin="anonymous"
                                />
                              </Media>
                              <div>
                                <div>{item.name}</div>
                                <div className="small text-muted">
                                  Créée le {dayjs(item.created).format('DD MMMM YYYY - HH[h]mm')}
                                </div>
                              </div>
                            </Link>

                          </td>
                          <td>{item.layers.length} {item.layers.length > 1 ? 'layers' : 'layer'}</td>
                          <td>
                            <div>{dayjs(item.updated).format('DD MMMM YYYY - HH[h]mm')}</div>
                          </td>
                          <td>
                            {
                              item.published ? (<Badge color="" className="badge-dot mr-4">
                                <i className="bg-success" /> Publié
                              </Badge>)
                                :
                                (<Badge color="" className="badge-dot mr-4">
                                  <i className="bg-warning" /> En attente de publication
                                </Badge>)
                            }

                          </td>
                          <td>
                            <UncontrolledDropdown direction="right">
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#"
                                role="button"
                                size="sm"
                                color=""
                                onClick={e => e.preventDefault()}
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                {
                                  item.published ?
                                    (<DropdownItem onClick={e => this.handlePublish(item, e)} className="text-warning">Retirer de la publication</DropdownItem>)
                                    :
                                    (<DropdownItem onClick={e => this.handlePublish(item, e)} className="text-success">Publier</DropdownItem>)
                                }
                                <DropdownItem className="text-danger" onClick={e => this.handleDeleteChapter(item, e)}>Supprimer</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })
                      : <tr>
                        <td>Aucun chapitre</td>
                      </tr>
                  }
                </tbody>
              </Table>
            </Card>
          </Col>

        </Row>
      );
    } else {
      return <Loading />;
    }
  }

  async handlePublish(item, e) {

    await AxiosService.chapter.publish(item.pk, !item.published)

    const timeline = await AxiosService.timeline.get(this.state.timeline.pk)
    this.setState({ timeline: timeline });

  }

  handlePublishStopPropagation(item, e) {
    e.stopPropagation()
  }

  async handleEditChapter(item, e) {
    try {

      this.setState({
        redirect: {
          path: `/editor/chapter/${item.pk}/`,
          props: {
            chapter: item,
            story: this.state.story.pk,
            timeline: this.state.timeline,
            medias: []
          }
        }
      });
    } catch (e) {
      alert(e)
    }


  }

  async handleCreateChapter(timelineId) {
    let chapterName = prompt(
      "Veuillez entrer le nom de votre chapitre",
      "Chapitre X"
    );

    let chapterOrder = prompt(
      "Quel est l'ordre de ce chapitre ?",
      1
    );

    if (chapterName == null || chapterName === "" || chapterOrder == null || chapterOrder === "") {
      alert("Veuillez renseigner un nom et un ordre");
    } else {
      try {
        const chapter = await AxiosService.chapter.create(timelineId, chapterName, chapterOrder)
        if (chapter !== false) {
          await AxiosService.layer.create("Main", 1.0, chapter.pk, true)
          const timeline = await AxiosService.timeline.get(timelineId)
          this.setState({ timeline: timeline });
        }
      } catch (e) {
        alert(e)
      }

    }
  }

  async handleDeleteChapter(item, e) {
    e.stopPropagation()
    if (window.confirm("Êtes-vous sûr de vouloir supprimer le chapitre " + item.name + " ?")) {
      try {
        await AxiosService.chapter.delete(item.pk)
        const timeline = await AxiosService.timeline.get(this.props.id)
        const story = await AxiosService.story.get(timeline.story)
        this.setState({
          timeline: timeline,
          story: story
        });
      } catch (e) {
        alert(e)
      }

    }
  }

  async componentDidMount() {
    try {
      const timeline = await AxiosService.timeline.get(this.props.id)
      const story = await AxiosService.story.get(timeline.story)
      this.setState({
        timeline: timeline,
        story: story
      });
    } catch (e) {
      alert(e)
    }

  }

  async handleChangeTimelineName(timelineId, storyId, newVersion) {
    let newName = prompt(
      "Renommer la timeline",
      ""
    );

    if (newName == null || newName === "") {
      alert("Veuillez renseigner un nom");
    } else {
      try {
        await AxiosService.timeline.rename(timelineId, newName)
        await AxiosService.story.updateVersion(storyId, newVersion)
        const timeline = await AxiosService.timeline.get(timelineId)
        const story = await AxiosService.story.get(timeline.story)
        this.setState({
          timeline: timeline,
          story: story
        });
      } catch (e) {
        alert(e)
      }

    }
  }

}

export default ViewTimeline;
