import React, { Component } from "react";
import AxiosService from "./../Api/AxiosService";
import { withCookies } from "react-cookie";
import { Redirect } from "react-router-dom";

class Logout extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirect: {
        path: "",
        props: {}
      },
    }

  }

  async componentDidMount() {
    try {
      const isLoggedIn = await AxiosService.auth.isLoggedIn()
      if (isLoggedIn) {
         if (AxiosService.auth.logout()) {
          this.setState({
            redirect: {
              path: "/login"
            }
          });
         }
      }
    } catch (e) {
      alert(e)
    }

  }

  render() {
    if (this.state.redirect.path !== "") {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect.path,
            state: { from: this.state.redirect.props }
          }}
        />
      );
    }
    
    return (
      <div>
        <div className="container">
          <div className="row">
            Déconnexion en cours...
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(Logout);
