import React from 'react';
import SoundLayerTimeline from './SoundLayerTimeline'

export default class SoundLayerRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return (
            <div
                style={{
                    height: "50px",
                    display: 'grid',
                    gridTemplateColumns: '0.4fr 1.6fr'
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: '15px'
                    }}
                >


                    <div
                        style={{
                            width: "40%",
                        }}
                    >
                        <h6
                            style={{
                                margin: "0",
                                padding: "0",
                                verticalAlign: "middle",
                                display: "table-cell",
                                color: "white",
                            }}
                        >
                            {this.props.soundLayer.name}
                        </h6>
                    </div>

                    <div
                        style={{
                            width: "20%",
                        }}
                    >
                        {/* Not implemented */}
                        <i className="fa fa-eye" aria-hidden="true" style={{ color: '#fff' }} />
                    </div>

                    <div
                        style={{
                            color: "white",
                            width: "20%",
                        }}
                    >

                    </div>

                    <div
                        style={{
                            color: "white",
                            width: "20%",
                        }}
                    >
                        <i className="fa fa-trash" onClick={() => this.props.onDeleteLayer(this.props.soundLayer)} aria-hidden="true"></i>
                    </div>
                </div>

                {/* Timeline */}
                <div>
                    <div style={{ width: "100%", height: "50px" }}>
                        <SoundLayerTimeline
                            soundLayer={this.props.soundLayer}
                            //key={this.props.key}
                            onRef={ref => { this.timeline = ref }}
                            wheelDelta={this.props.wheelDelta}
                            onScrollTimeline={this.props.onScrollTimeline}
                            onSaveNeeded={this.props.onSaveNeeded}
                            handleUpdateSoundManager={arrObj => { this.props.handleUpdateSoundManager(arrObj) }}
                            handleDeleteObjectInSoundManager={uniqueID => { this.props.handleDeleteObjectInSoundManager(uniqueID) }}
                        />
                    </div>
                </div>

            </div>

        )
    }

    async componentDidMount() {
        this.props.onRef(this)
    }

    soundsPromise() {
        return this.timeline.soundsData()
    }

}
