import React from 'react';
import fabricImport from "fabric";

const fabric = fabricImport.fabric;

export default class LayerTriggers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            
            <div 
            id={"triggers-canvas-container" + this.props.layer.pk}
            key={this.props.key}
            style={{
                width: '100%',
                height: '100%',
                borderTop: '1px solid #c0c0c0',
                backgroundColor:"#e5e5e5"
            }}
            >
                <canvas
                id={"triggers-canvas-timeline" + this.props.layer.pk}
                style={{
                    width: '100%',
                    height: '100%',
                }}
                >

                </canvas>
            </div>

        )
    }

    async componentDidMount() {
        await initTriggerCanvas(this)
        this.props.onRef(this)

        document.getElementById("triggers-canvas-container" + this.props.layer.pk).addEventListener('wheel', event => {
            this.handleScroll(event)
        })
        
    }

    async componentDidUpdate(previousProps, previousState){
        
        this._relativeScrollCanvas(this.props.wheelDelta)

    }

    _relativeScrollCanvas(delta) {

        let canvas = this.state.triggerCanvas
        let point = new fabric.Point(delta * this.props.layer.scrollRatio, 0)
        
        canvas.relativePan(point);
        
        canvas.renderAll()

    }

    handleScroll(e){

        // Stop the scroll event to avoid scrolling the entire webpage
        e.preventDefault()
        e.stopPropagation()
        
        this.props.onScrollTimeline(e.wheelDelta)
    }

    _handleTriggerDropped(e) {

        // handleTriggerDrop method is responsible for creating a new fabric Image and add it to the current trigger canvas

        if (e.stopPropagation) {
            e.stopPropagation();
        }

        let triggerCanvas = this.state.triggerCanvas

        let pointer = triggerCanvas.getPointer(e, true);

        let img = document.querySelector('img.trigger_type_dragging');

        if(img !== null){

            let posX = pointer.x - triggerCanvas.viewportTransform[4];

            //let posX = pointer.x;
            let posY = pointer.y;
    
            let width = img.naturalWidth
            let height = img.naturalHeight

            let canvasHeight = document.getElementById('storyDiv').clientHeight * 2;
            let timelineHeight = document.getElementById("canvas-container" + this.props.layer.pk).clientHeight;

            let trueX =  posX * canvasHeight / timelineHeight

            let newImage = new fabric.Image(img, {
                width: width,
                height: height,
                lockMovementY: true,
                lockMovementX: false,
                lockScalingX: true,
                lockScalingY: true,
                left: posX,
                top: posY,
                opacity: 1,
                hasRotatingPoint: true,
                objectCaching: false,
                statefullCache: false,
                noScaleCache: true,
                crossOrigin: 'anonymous',
    
                tmpTriggerId: this.uuidv4,
                layer: this.props.layer.pk,
                triggerType: img.alt,
                hasControls: false,
                hasBorders: false,
                trueX: trueX
            });

            // If the image has a height bigger than the canvas, we resize it to fit in
            if (height > triggerCanvas.height) {
                newImage.scaleToHeight(triggerCanvas.height)
                newImage.top = 0
            }
    
            newImage.toObject = (function (toObject) {
                return function () {
                    return fabric.util.object.extend(toObject.call(this), {
                        crossOrigin: 'anonymous',
                        tmpTriggerId: this.tmpTriggerId,
                        layer: this.layer,
                        state: this.state,
                        triggerType: this.triggerType,
                        lockMovementX: this.lockMovementX,
                        lockMovementY: this.lockMovementY,
                        lockScalingX: this.lockScalingX,
                        lockScalingY: this.lockScalingY,
                        hasControls: this.hasControls,
                        hasBorders: this.hasBorders,
                        trueX: this.trueX
                    });
                };
            })(newImage.toObject);
    
            triggerCanvas.add(newImage);
        }
        
    }

    triggersData = () => {
        return new Promise((resolve, reject) => {
            let triggerCanvas = this.state.triggerCanvas
            let jsonValue = JSON.stringify(triggerCanvas.toJSON(["lockMovementX", "lockMovementY", "lockScalingX", "lockScalingY", "hasBorders", "hasControls", "triggerType", "trueX"]))

            let data = {
                layer: this.props.layer,
                jsonTriggers: jsonValue
            }
            
            resolve(data)

        })
    }

    uuidv4(){return"00-0-0-0-000".replace(/0/g,function(){return Math.random().toString(16).substr(2,4)})}

    
}

const initTriggerCanvas = async function (self) {

    // Foreach layer of the chapter, we create a fabric canvas

    // We get back the previously created HTML canvas in our render() method
    let canvas = new fabric.Canvas("triggers-canvas-timeline" + self.props.layer.pk);
    //document.getElementById("triggers-canvas-timeline").fabric = canvas

    // We set the height and width to x2 with backstoreOnly to false, this allow us to show a 640px canvas into a 320px div
    let h = document.getElementById("triggers-canvas-container" + self.props.layer.pk).clientHeight;
    let w = document.getElementById("triggers-canvas-container" + self.props.layer.pk).clientWidth;

    canvas.setHeight(h, { backstoreOnly: false });
    canvas.setWidth(w, { backstoreOnly: false });

    // Canvas general configuration
    canvas.allowTouchScrolling = true
    canvas.preserveObjectStacking = true

    canvas.selection = false
    canvas.hasControls = false

    // We then load the fabric json in our canvas if we have it
    let jsonTriggers = self.props.layer.jsonTriggers;
    if (jsonTriggers !== null && jsonTriggers !== "") {
        canvas = canvas.loadFromJSON(jsonTriggers, function () {        
            
        });
    }

    canvas.on("drop", obj => {
        self._handleTriggerDropped(obj.e)
    });         
    
    canvas.on("object:moving", obj => {
        
        canvas.on('mouse:out', obj => {
            if(canvas.getActiveObject() !== null){
                document.body.style.cursor = "no-drop"
            }
        })

        canvas.on('mouse:over', obj => {
            document.body.style.cursor = "default"
        })

    })

    canvas.on('mouse:up', obj => {

        if (document.body.style.cursor === "no-drop" && canvas.getActiveObject() !== null) {
            // Released outside of the canvas
            canvas.remove(canvas.getActiveObject())
        } 

        canvas.discardActiveObject()
        document.body.style.cursor = "default"
    })
    
    canvas.on("object:moved", function (e) {
        let obj = e.target;

        let canvasHeight = document.getElementById('storyDiv').clientHeight * 2;
        let timelineHeight = document.getElementById("canvas-container" + self.props.layer.pk).clientHeight;

        obj.trueX = obj.left * canvasHeight / timelineHeight

    })

    self.setState({
        triggerCanvas: canvas
    })
    
};
