import React, { Component } from 'react';
import {
    Row, Col, Input, FormFeedback, Label
} from 'reactstrap';
import AxiosService from '../../Api/AxiosService';
import { toast } from 'react-toastify';

class UpdateProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            confirmPassword: '',
        };
    }

    isPasswordConfirmationOk = (password, confirmPassword) => {
        if(password === '' || confirmPassword === '')
            return true;
        return password === confirmPassword;
    }

    updateProfile = async (password, confirmPassword) => {
        if (password !== '' && this.isPasswordConfirmationOk(password, confirmPassword)) {
            try {
                await AxiosService.auth.updateProfile(password);
                this.setState({password: '', confirmPassword: ''});
                toast.success('Le profil a été mis à jour');

            } catch (error) {
                toast.error('Une erreur interne s\'est produite.');
                console.log('ERROR', error)
            }
        }
        else{
            toast.warning('Veuillez corriger votre saisie.');
        }
    }

    render() {
        const { password, confirmPassword } = this.state;
        return (
            <Row>
                <Col>
                    <div className="bg-secondary shadow card">
                        <div className="bg-white border-0 card-header">
                            <div className="align-items-center row">
                                <div className="col-8">
                                    <h3 className="mb-0">Mon compte</h3></div>
                                <div className="text-right col-4">
                                    <button className="btn btn-primary btn-sm" onClick={() => this.updateProfile(password, confirmPassword)}>Mettre à jour</button>
                                </div></div></div>
                        <div className="card-body">
                            <h6 className="heading-small text-muted mb-4">Modifier le mot de passe</h6>
                            <div className="pl-lg-4">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <Label className="form-control-label" htmlFor="input-password">Nouveau mot de passe</Label>
                                            <Input id="input-password" type="password" onChange={e => this.setState({ password: e.target.value })}
                                                value={password} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <Label className="form-control-label" htmlFor="input-confirm-password">Ressaisir le nouveau mot de passe</Label>
                                            <Input id="input-confirm-password" type="password" onChange={e => this.setState({ confirmPassword: e.target.value })}
                                                value={confirmPassword} invalid={!this.isPasswordConfirmationOk(password, confirmPassword)} />
                                            {!this.isPasswordConfirmationOk(password, confirmPassword) && <FormFeedback>Confirmation du mot de passe incorrecte</FormFeedback>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <hr className="my-4" /><h6 className="heading-small text-muted mb-4">Contact information</h6><div className="pl-lg-4"><div className="row"><div className="col-md-12"><div className="form-group"><label className="form-control-label" for="input-address">Address</label><input id="input-address" placeholder="Home Address" type="text" className="form-control-alternative form-control" value="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09" /></div></div></div><div className="row"><div className="col-lg-4"><div className="form-group"><label className="form-control-label" for="input-city">City</label><input id="input-city" placeholder="City" type="text" className="form-control-alternative form-control" value="New York" /></div></div><div className="col-lg-4"><div className="form-group"><label className="form-control-label" for="input-country">Country</label><input id="input-country" placeholder="Country" type="text" className="form-control-alternative form-control" value="United States" /></div></div><div className="col-lg-4"><div className="form-group"><label className="form-control-label" for="input-country">Postal code</label><input id="input-postal-code" placeholder="Postal code" type="number" className="form-control-alternative form-control" /></div></div></div></div><hr className="my-4" /><h6 className="heading-small text-muted mb-4">About me</h6><div className="pl-lg-4"><div className="form-group"><label>About Me</label><textarea placeholder="A few words about you ..." rows="4" className="form-control-alternative form-control">A beautiful Dashboard for Bootstrap 4. It is Free and Open Source.</textarea></div></div> */}
                        </div>
                    </div>
                </Col>
            </Row>

        )
    }
}

export default UpdateProfile;
