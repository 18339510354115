import React, { Component } from "react";
import AxiosService from "./../../Api/AxiosService"
import { Link } from "react-router-dom";
import {
  Table, UncontrolledDropdown, DropdownToggle, DropdownItem,
  DropdownMenu, Row, Col, Card,
  Media, Badge
} from 'reactstrap';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
dayjs.locale('fr')

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stories: []
    };

    this.handleCreateStory = this.handleCreateStory.bind(this);
  }

  render() {
    return (
      <>
        <Row>
          {/* <Col lg="6" xl="3" className="mb-5">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Traffic
                          </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      350,897
                          </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                      <i className="fas fa-chart-bar" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="text-success mr-2">
                    <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                  <span className="text-nowrap">Since last month</span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" xl="3">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      New users
                          </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      2,356
                          </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                      <i className="fas fa-chart-pie" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="text-danger mr-2">
                    <i className="fas fa-arrow-down" /> 3.48%
                        </span>{" "}
                  <span className="text-nowrap">Since last week</span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" xl="3">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Sales
                          </CardTitle>
                    <span className="h2 font-weight-bold mb-0">924</span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                      <i className="fas fa-users" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="text-warning mr-2">
                    <i className="fas fa-arrow-down" /> 1.10%
                        </span>{" "}
                  <span className="text-nowrap">Since yesterday</span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" xl="3">
            <Card className="card-stats mb-4 mb-xl-0">
              <CardBody>
                <Row>
                  <div className="col">
                    <CardTitle
                      tag="h5"
                      className="text-uppercase text-muted mb-0"
                    >
                      Performance
                          </CardTitle>
                    <span className="h2 font-weight-bold mb-0">
                      49,65%
                          </span>
                  </div>
                  <Col className="col-auto">
                    <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                      <i className="fas fa-percent" />
                    </div>
                  </Col>
                </Row>
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="text-success mr-2">
                    <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                  <span className="text-nowrap">Since last month</span>
                </p>
              </CardBody>
            </Card>
          </Col> */}

          <Col>
            <Card>
              <div className="border-0 card-header">
                <div className="align-items-center row">
                  <div className="col">
                    <h3 className="mb-0">
                      Mes frises
                    </h3>
                  </div>
                  <div className="col text-right">
                    <span onClick={this.handleCreateStory} className="btn btn-primary">Créer une frise</span>
                  </div>
                </div>
              </div>

              <Table
                className="align-items-center table"
                responsive
              >
                <thead className="thead">
                  <tr>
                    <th scope="col">Frise</th>
                    <th scope="col">Timelines</th>
                    <th scope="col">Dernière modification</th>
                    <th scope="col">Publié</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {
                    (this.state.stories !== null && this.state.stories.length > 0) ? this.state.stories.map((item, i) => {
                      return (
                        <tr key={i}>
                          <th scope="row">
                            <Link to={{
                              pathname: `/story/${item.pk}/`,
                              state: {
                                story: item,
                              }
                            }}>
                              <Media className="align-items-center">
                                <img
                                  className="avatar rounded mr-3"
                                  alt="..."
                                  src="https://api.frise.io/uploads/chapters/44_1584543890.0.jpg"
                                />
                                <Media>
                                  <span className="mb-0 text-sm">
                                    {item.name}
                                  </span>
                                </Media>
                              </Media>
                            </Link>
                          </th>
                          <td>{item.timelines.length} {item.timelines.length > 1 ? 'timelines' : 'timeline'}</td>
                          <td>{dayjs(item.updated).format('DD MMMM YYYY - HH[h]mm')}</td>
                          <td>
                            {
                              item.published ? (<Badge color="" className="badge-dot mr-4">
                                <i className="bg-success" /> Publié
                              </Badge>)
                                :
                                (<Badge color="" className="badge-dot mr-4">
                                  <i className="bg-warning" /> En attente de publication
                                </Badge>)
                            }

                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#"
                                role="button"
                                size="sm"
                                color=""
                                onClick={e => e.preventDefault()}
                              >
                                <i className="fa fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                {
                                  item.published ?
                                    (<DropdownItem onClick={e => this.handlePublish(item, e)} className="text-warning">Retirer de la publication</DropdownItem>)
                                    :
                                    (<DropdownItem onClick={e => this.handlePublish(item, e)} className="text-success">Publier</DropdownItem>)
                                }

                                <DropdownItem onClick={e => this.handleDeleteStory(item, e)} className="text-danger">Supprimer</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })
                      : <tr>
                        <td>Aucune frise créée</td>
                      </tr>
                  }
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  async handlePublish(item, e) {
    await AxiosService.story.publish(item.pk, !item.published)
    const stories = await AxiosService.story.get()
    this.setState({ stories: stories });
  }

  handlePublishStopPropagation(item, e) {
    e.stopPropagation()
  }

  async handleCreateStory() {
    let friseName = prompt(
      "Veuillez entrer le nom de votre frise",
      "Histoire X"
    );

    if (friseName === null || friseName === "") {
      alert("Veuillez renseigner un nom");
    } else {
      try {
        const story = await AxiosService.story.create(friseName)
        if (story) {
          const stories = await AxiosService.story.get()
          this.setState({ stories: stories });
        }
      } catch (e) {
        alert(e)
      }

    }
  }

  async handleDeleteStory(item, e) {

    e.stopPropagation()

    if (window.confirm("Êtes-vous sûr de vouloir supprimer la frise " + item.name + " ?")) {
      try {
        await AxiosService.story.delete(item.pk)
        const result = await AxiosService.story.get()
        this.setState({
          stories: result
        });
      } catch (e) {
        alert(e)
      }

    }

  }

  async componentDidMount() {
    try {
      const stories = await AxiosService.story.get()
      this.setState({ stories: stories });
    } catch (e) {
      alert(e)
    }

  }
}

export default Dashboard;
