import React, { Component } from "react";
import AxiosService from "./../Api/AxiosService";
import { withCookies } from "react-cookie";
import { Redirect } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      redirect: {
        path: "",
        props: {}
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  async handleSubmit(event, email, password) {
    event.preventDefault();

    try {
      await AxiosService.auth.login(email, password)

      this.setState({
        redirect: {
          path: "/"
        }
      });
    } catch (e) {
      alert(e)
    }

  }

  handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }
  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  async componentDidMount() {
    try {
      const isLoggedIn = await AxiosService.auth.isLoggedIn()
      if (isLoggedIn) {
        this.setState({
          redirect: {
            path: "/"
          }
        });
      }
    } catch (e) {
      alert(e)
    }

  }

  render() {

    if (this.state.redirect.path !== "") {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect.path,
            state: { from: this.state.redirect.props }
          }}
        />
      );
    }
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col col-login mx-auto">
              <div className="text-center mb-6">
                <img src="./assets/home/brand/tabler.svg" className="h-6" alt="" />
              </div>
              <form
                className="card"
                onSubmit={e =>
                  this.handleSubmit(e, this.state.email, this.state.password)
                }
              >
                <div className="card-body p-6">
                  <div className="card-title">Connexion à Frise.io</div>
                  <div className="form-group">
                    <label className="form-label">Adresse email</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      value={this.state.email}
                      onChange={this.handleChangeEmail}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label">
                      Mot de passe
                      {/* <a
                        href="./forgot-password.html"
                        className="float-right small"
                      >
                        Mot de passe oublié
                      </a> */}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handleChangePassword}
                    />
                  </div>
                  {/* <div className="form-group">
                    <label className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" />
                      <span className="custom-control-label">Se souvenir de moi</span>
                    </label>
                  </div> */}
                  <div className="form-footer">
                    <button type="submit" className="btn btn-primary btn-block">
                      Connexion
                    </button>
                  </div>
                </div>
              </form>
              {/* <div className="text-center text-muted">
                Don't have account yet? <a href="./register.html">Sign up</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withCookies(Login);
