import { apiClient } from "./AxiosService"

class TimelineService {

    static async getByStory(storyId){
        return apiClient
            .get(`timeline?story=${storyId}`)
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error
            });
    }
    
    static async retrieve(id) {
        return apiClient
            .get(`timeline/${id}/`)
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error
            });
    }

    static async create(story, name, order) {
        return apiClient
            .post(
                "timeline/",
                { name, order, story },
            )
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error
            });
    }

    static async delete(id) {
        
        return apiClient
            .delete(`timeline/${id}/`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error
            });
    }

    static async rename(id, name) {

        return apiClient
            .put(`timeline/${id}/`,
            { name })
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error;
            });
    }

}

export default TimelineService;
