import React from 'react';
import fabricImport from "fabric";

const fabric = fabricImport.fabric;

fabric.LabeledRect = fabric.util.createClass(fabric.Rect, {

    type: 'LabeledRect',

    initialize: function (options) {
        options || (options = {});
        this.lockMovementY = true
        this.lockRotation = true
        this.lockScalingY = true
        this.callSuper('initialize', options);
        this.set('label', options.label || '');
        this.on('scaling', (e) => {
            // console.log(this.getContext('2d'))
            // let ctx = this.canvas.getContext('2d')
            // //console.log(ctx)

            // var metrics = ctx.measureText(this.label)
            // var textWidth = metrics.width

            // let rectScaleX = this.scaleX
            // ctx.scale(1 / rectScaleX, 1)
            // ctx.fillText(this.label, 5, 5, textWidth)

        })

    },

    toObject: function () {
        return fabric.util.object.extend(this.callSuper('toObject'), {
            label: this.get('label'),
            mediaId: this.get('mediaId'),
            mediaUrl: this.get('mediaUrl'),
            uniqueID: this.get('uniqueID'),
            canvasHeight: this.get('canvasHeight')
        });
    },

    _render: function (ctx) {
        // console.log("LabeledRect render called")
        // console.log(ctx)
        this.callSuper('_render', ctx)

        ctx.font = '16px Helvetica'
        ctx.fillStyle = '#ffffff'

        // var metrics = ctx.measureText(this.label)
        // var textWidth = metrics.width

        let rectScaleX = this.scaleX
        ctx.scale(1 / rectScaleX, 1)
        ctx.fillText(this.label, 0, 5)

    }
});

fabric.LabeledRect.fromObject = function (object, callback) {
    return fabric.Object._fromObject('LabeledRect', object, callback);
};

export default class SoundLayerTimeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (

            <div
                id={"sound-canvas-container" + this.props.soundLayer.pk}
                key={this.props.key}
                style={{
                    width: '100%',
                    height: '100%',

                    borderBottom: '1px solid #c0c0c0',
                    backgroundColor: "#606060"
                }}
            >
                <canvas
                    id={"sound-canvas-timeline" + this.props.soundLayer.pk}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                >

                </canvas>
            </div>

        )
    }

    async componentDidMount() {

        await initSoundCanvas(this)
        this.props.onRef(this)

        document.getElementById("sound-canvas-container" + this.props.soundLayer.pk).addEventListener('wheel', event => {
            this.handleScroll(event)
        })

    }

    async componentDidUpdate() {
        // await updateSoundCanvas(this)

        this._relativeScrollCanvas(this.props.wheelDelta)
    }


    handleScroll(e) {

        // Stop the scroll event to avoid scrolling the entire webpage
        e.preventDefault()
        e.stopPropagation()

        //this._relativeScrollCanvas(e.wheelDelta)
        this.props.onScrollTimeline(e.wheelDelta)
    }


    // _relativeScrollCanvas(delta) {

    //     let canvas = this.state.soundCanvas
    //     let point = new fabric.Point(delta, 0)

    //     canvas.relativePan(point);

    //     canvas.renderAll()

    // }

    _relativeScrollCanvas(delta) {

        let canvas = this.state.soundCanvas

        if(canvas.viewportTransform[4] + delta > 0){
            let point = new fabric.Point(0, 0)
            canvas.absolutePan(point)
        }else{
            let point = new fabric.Point(delta, 0)
            canvas.relativePan(point)
        }


        // let canvas = this.state.timelineCanvas

        // let move = delta * this.props.layer.scrollRatio

        // if (canvas.viewportTransform[4] + move > 0) {
        //     let point = new fabric.Point(0, 0)
        //     canvas.absolutePan(point)
        // } else {
        //     let point = new fabric.Point(delta * this.props.layer.scrollRatio, 0)
        //     canvas.relativePan(point);
        // }
        // canvas.renderAll()

    }


    _handleSoundDropped(e) {

        // handleTriggerDrop method is responsible for creating a new fabric Image and add it to the current trigger canvas

        if (e.stopPropagation) {
            e.stopPropagation();
        }

        let soundCanvas = this.state.soundCanvas

        let pointer = soundCanvas.getPointer(e, true);

        let img = document.querySelector('img.sound_dragging');
        // console.log("SOUND")
        // console.log(img)
        if (img !== null) {

            let posX = pointer.x;

            let width = 200
            let height = soundCanvas.height - 10

            // data-mediaoriginalname={media.originalName}
            // data-mediaid={media.id}
            // data-mediaurl={AxiosService.media.getMediaUrl(media.file)}

            var labeledRect = new fabric.LabeledRect({
                width: width,
                height: height,
                left: posX,
                top: 5,
                label: img.getAttribute('data-mediaoriginalname'),
                fill: '#008000',
                mediaId: img.getAttribute('data-mediaid'),
                mediaUrl: img.getAttribute('data-mediaurl'),
                uniqueID: this.uuidv4(),
                canvasHeight: soundCanvas.height
            });

            soundCanvas.add(labeledRect);
            soundCanvas.renderAll()
        }

        this.props.onSaveNeeded()

        // Inform the SoundManager of the new sound that should be played
        let obj = {
            mediaId: labeledRect.mediaId,
            mediaUrl: labeledRect.mediaUrl,
            uniqueID: labeledRect.uniqueID,
            x: labeledRect.left,
            width: labeledRect.width,
            canvasHeight: labeledRect.canvasHeight
        }

        this.props.handleUpdateSoundManager([obj])

    }

    uuidv4() { return "00-0-0-0-000".replace(/0/g, function () { return Math.random().toString(16).substr(2, 4) }) }

    soundsData = () => {
        return new Promise((resolve, reject) => {
            let soundCanvas = this.state.soundCanvas
            let jsonValue = JSON.stringify(soundCanvas.toJSON())

            let data = {
                layer: this.props.soundLayer,
                jsonSounds: jsonValue
            }

            resolve(data)

        })
    }

    _manageJsonForSoundManager(jsonSounds){

        let arrObj = []
        let json = JSON.parse(jsonSounds)

        console.log(json)
        json["objects"].forEach(element => {
            console.log("element.width : " + element.width)
            let obj = {
                mediaId: element.mediaId,
                mediaUrl: element.mediaUrl,
                uniqueID: element.uniqueID,
                x: element.left,
                width: element.width * element.scaleX,
                canvasHeight: element.canvasHeight
            }
            arrObj.push(obj)
        });
    
        this.props.handleUpdateSoundManager(arrObj)
    }
    

    

}

const initSoundCanvas = async function (self) {

    // // Foreach layer of the chapter, we create a fabric canvas
    let soundLayer = self.props.soundLayer

    // We get back the previously created HTML canvas in our render() method
    let canvas = new fabric.Canvas("sound-canvas-timeline" + soundLayer.pk);

    // We set the height and width to x2 with backstoreOnly to false, this allow us to show a 640px canvas into a 320px div
    let h = document.getElementById("sound-canvas-container" + soundLayer.pk).clientHeight;
    let w = document.getElementById("sound-canvas-container" + soundLayer.pk).clientWidth;

    canvas.setHeight(h, { backstoreOnly: false });
    canvas.setWidth(w, { backstoreOnly: false });

    // Canvas general configuration
    canvas.allowTouchScrolling = true
    canvas.preserveObjectStacking = true

    canvas.selection = false

    // We then load the fabric json in our canvas if we have it
    let jsonSounds = self.props.soundLayer.json;
    if (jsonSounds !== null && jsonSounds !== "") {
        canvas = canvas.loadFromJSON(jsonSounds, function () {
            //canvas.renderAll()
            self._manageJsonForSoundManager(jsonSounds)
            
        });
    }

    canvas.on("drop", obj => {
        self._handleSoundDropped(obj.e)
    });

    canvas.on("object:moving", obj => {

        canvas.on('mouse:out', obj => {
            if (canvas.getActiveObject() !== null) {
                document.body.style.cursor = "no-drop"
            }
        })

        canvas.on('mouse:over', obj => {
            document.body.style.cursor = "default"
        })

    })

    canvas.on('mouse:up', obj => {

        if (document.body.style.cursor === "no-drop" && canvas.getActiveObject() !== null) {
            // Released outside of the canvas
            let deletedObject = canvas.getActiveObject()
            let uniqueID = deletedObject.uniqueID
            canvas.remove(deletedObject)
            canvas.discardActiveObject()
            self.props.onSaveNeeded()
            self.props.handleDeleteObjectInSoundManager(uniqueID)
        }


        document.body.style.cursor = "default"
    })

    canvas.on('object:moved', obj => {
        self.props.onSaveNeeded()
        
        // Optimisation : on passe le json en string pour directement le repasser en json dans la méthode _manageJsonForSoundManager
        let jsonSounds = JSON.stringify(canvas.toJSON())
        self._manageJsonForSoundManager(jsonSounds)
    })

    self.setState({
        soundCanvas: canvas
    })

};

// const updateSoundCanvas = async function (self) {

//     // Foreach layer of the chapter, we create a fabric canvas
//     let soundLayer = self.props.soundLayer

//     // We get back the previously created HTML canvas in our render() method
//     let canvas = document.getElementById("sound-canvas-timeline" + soundLayer.pk).fabric

//     // if(canvas){
//     //     let jsonValue = JSON.stringify(tmpcanvas.toJSON(['layer', 'canMoveOutside']))

//     //     //let json = layer.json;
//     //     if (jsonValue !== null && jsonValue !== "") {
//     //         canvas = canvas.loadFromJSON(jsonValue, function () {
//     //             canvas.getObjects().map(obj => {
//     //                 obj.evented = false
//     //                 obj.selectable = false
//     //             })
//     //         });
//     //     }

//     // }

// };