import React, { Component } from "react";
import Header from "./Header";
import Sidebar from './Sidebar';
import { Container } from 'reactstrap';

import "../../../assets/dashboard/plugins/nucleo/css/nucleo.css";
import "../../../assets/dashboard/css/argon-dashboard-react.css";

class StandardPage extends Component {
  render() {
    const routes = [
      {
        to: "/",
        name: "Mes frises",
        icon: "ni ni-tv-2 text-primary",
        component: {},
      },
      {
        to: "/profile",
        name: "Mon profil",
        icon: "ni ni-single-02 text-yellow",
        component: {},
      },
      // {
      //   to: "/statitics",
      //   name: "Statistiques",
      //   icon: "ni ni-chart-bar-32 text-red",
      //   component: {},
      // },
      // {
      //   to: "/notifications",
      //   name: "Notifications",
      //   icon: "ni ni-chat-round text-green",
      //   component: {},
      // },
      // {
      //   to: "/factures",
      //   name: "Mes factures",
      //   icon: "ni ni-single-copy-04 text-primary",
      //   component: {},
      // },
      // {
      //   to: "/subscription",
      //   name: "Abonnement",
      //   icon: "ni ni-credit-card text-primary",
      //   component: {},
      // },
      {
        to: "/logout",
        name: "Déconnexion",
        icon: "ni ni-button-power text-red",
        component: {},
      },
    ];
    return (
      <>

        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/",
            imgSrc: require("../../../assets/images/frise-logo.png"),
            imgAlt: "..."
          }}
        />


        <div className="main-content" ref="mainContent">
          <Header />
          <div className="header bg-gradient-main pb-8 pt-5 pt-md-8"></div>
          <Container fluid className="mt--7">

            {this.props.component}

          </Container>
        </div>

      </>
    );
  }
}

export default StandardPage;
