import React from 'react';

export default class SoundManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            soundActivated: false,
            position: -1
        }

        this.arrSound = []

        this.onActivateSound = this.onActivateSound.bind(this)
        this.updateAudio = this.updateAudio.bind(this)
    }

    render() {
        let button;
        if (this.state.soundActivated) {
            button = (
                <>
                    <i className="fa fa-volume-up dock-item-img" aria-hidden="true"></i>
                    <span className="dock-item-title">Désactiver</span>
                </>)
        }
        else {
            button = (
                <>
                    <i className="fa fa-volume-off dock-item-img" aria-hidden="true"></i>
                    <span className="dock-item-title">Activer</span>
                </>)
        }
        return (

            <div className="dock-item" onClick={this.onActivateSound}>
                {button}
            </div>

            // <div style={{ marginTop: 'auto', padding: '15px 15px' }}>
            //     <h6
            //         style={{
            //             margin: "0",
            //             padding: "0",
            //             verticalAlign: "middle",
            //             display: "table-cell",
            //             cursor: "pointer",
            //             color: (this.state.soundActivated) ? "#eec165" : "white",
            //         }}
            //         onClick={this.onActivateSound}
            //     >

            //         Activer son
            //     </h6>
            // </div>
        )
    }

    async componentDidMount() {
        this.props.onRef(this)
    }

    async componentDidUpdate(previousProps, previousState) {

        if (!this.state.soundActivated) {
            // Stop all audio when we deactivate sound
            this.arrSound.forEach(element => {
                let audio = element.audio
                if (!audio.paused) {
                    audio.stop()
                }
            })
        } else {
            // Restart audio when we activate sound
            console.log("component did update")
            this.onPreviewScroll(this.state.position)
        }
    }


    onActivateSound() {
        let activated = this.state.soundActivated
        this.setState({
            soundActivated: !activated
        })
    }

    onPreviewScroll(position) {
        //console.log("on preview scroll")
        if (this.state.soundActivated) {

            console.log("Sound is activated")
            console.log("Parsing audio to check if we should play a song...")

            // Width of the story div
            let previewWidth = document.getElementById('storyDiv').clientWidth * 2
            let previewHeight = document.getElementById('storyDiv').clientHeight * 2

            this.arrSound.forEach(sound => {
                console.log(sound)


                //  // Width of the story div
                //  let previewWidth = document.getElementById('storyDiv').clientWidth * 2
                //  let previewHeight = document.getElementById('storyDiv').clientHeight * 2

                //  let canvasHeight = 39

                //  let convertedX = element.x * previewHeight / canvasHeight
                //  let convertedW = element.width * previewHeight / canvasHeight

                //  console.log("originalX : " + element.x)
                //  console.log("originalW : " + element.width)
                //  console.log("element.canvasHeight : " + canvasHeight)
                //  console.log("previewHeight : " + previewHeight)
                //  console.log("convertedX : " + convertedX)
                //  console.log("convertedW : " + convertedW)

                //  var rect = new fabric.Rect({
                //      left: convertedX,
                //      top: 50,
                //      width: convertedW,
                //      height: 50,
                //      fill: "green",
                //  });


                //  mainCanvas.add(rect)

                // This is the height of the layerRows canvas
                let canvasHeight = 39

                let convertedX = sound.x * previewHeight / canvasHeight
                let convertedW = sound.width * previewHeight / canvasHeight

                // console.log("convertedX : " + convertedX)
                // console.log("convertedW : " + convertedW)

                // Absolute value of the position in our preview
                let absPosition = Math.abs(position)

                let l1 = {
                    x: absPosition
                }

                let l2 = {
                    x: convertedX
                }

                let r1 = {
                    x: absPosition + previewWidth
                }

                let r2 = {
                    x: convertedX + convertedW
                }

                let relativeSound = this.arrSound.find(element => element.uniqueID === sound.uniqueID)

                // Check if rectangles overlap
                if (l1.x > r2.x || l2.x > r1.x) {
                    // Not overlapping
                    console.log("not overlapping")
                    let audio = relativeSound.audio
                    if (!audio.paused) {
                        audio.stop()
                    }

                } else {
                    // Overlapping
                    console.log("PLAY MUSIC")
                    relativeSound.audio.play()

                }
            })

        }

        if (this.state.position !== position) {
            this.setState({
                position: position
            })
        }

    }

    async updateAudio(arrObj) {
        // console.log("SoundManager received new objects : ")
        // arrObj.forEach(element => {
        //     console.log(element.mediaId)
        // })

        let tmpArrSound = this.arrSound

        arrObj.forEach(element => {

            let relativeSound = tmpArrSound.find(sound => sound.uniqueID === element.uniqueID)
            if (relativeSound) {
                let audio = relativeSound.audio
                if (!audio.paused) {
                    audio.stop()
                }
            }

            // We remove the objects from our state's arrays if we have it, relative to the uniqueID
            tmpArrSound = tmpArrSound.filter(sound => sound.uniqueID !== element.uniqueID)

            // Then we push the obj and its associated audio player in our arrays
            element.audio = new Audio(element.mediaUrl)

            tmpArrSound.push(element)
        });

        this.arrSound = tmpArrSound
        // console.log("arrSound is now :")
        // this.arrSound.forEach(element => {
        //     console.log(element.mediaId)
        // })

        this.onPreviewScroll(this.state.position)
    }

    deleteObject(uniqueID) {
        let tmpArrSound = this.arrSound

        let relativeSound = tmpArrSound.find(sound => sound.uniqueID === uniqueID)
        if (relativeSound) {
            let audio = relativeSound.audio
            if (!audio.paused) {
                audio.stop()
            }
        }

        // We remove the objects from our state's arrays if we have it, relative to the uniqueID
        tmpArrSound = tmpArrSound.filter(sound => sound.uniqueID !== uniqueID)

        this.arrSound = tmpArrSound
        //console.log("object deleted in SoundManager")
    }


}


Audio.prototype.stop = function () {
    this.pause();
    this.currentTime = 0;
};
