import React, { Component } from "react";
import { Link } from "react-router-dom";
import AxiosService from "../../../Api/AxiosService"
import { Cookies } from "react-cookie";
import { Redirect } from "react-router-dom";

import {
  Navbar,
  Container,
} from "reactstrap";



let cookie = new Cookies();

class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirect: {
        path: "",
        props: {}
      },
      infos: null
    };

    this.handleLogout = this.handleLogout.bind(this);
  }

  async componentDidMount() {
    try {
      const infos = await AxiosService.auth.infos()
      if (infos) {
        this.setState({
          infos: infos
        })
      } else {
        this.setState({
          redirect: {
            path: `/login`,
            props: {}
          }
        });
      }
    } catch (e) {
      this.setState({
        redirect: {
          path: `/login`,
          props: {}
        }
      });
    }

  }

  handleLogout() {
    cookie.remove("jwt");

    this.setState({
      redirect: {
        path: `/login`,
        props: {}
      }
    });

  }

  render() {
    if (this.state.redirect.path !== "") {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect.path,
            state: { from: this.state.redirect.props }
          }}
        />
      );
    }
    // const infos = this.state.infos;
    return (

      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {/* {this.props.brandText} Tableau de bord */}
            </Link>

          </Container>
        </Navbar>
      </>

    );
  }
}

export default Header;
