import React, { Component } from "react";
import Loading from "../Layout/Loading";
import { Link } from "react-router-dom";
import AxiosService from "../../Api/AxiosService";
import { Table, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Col, Card, Row } from 'reactstrap';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
dayjs.locale('fr')

class ViewStory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      story: null,
      deleteForm: ""
    };

  }

  render() {
    if (this.state.story != null) {
      return (
        <Row>
          <Col>
            <Card>
              <div className="border-0 card-header">
                <div className="align-items-center row">
                  <div className="col">
                    <h3 className="mb-0">
                      Timelines de <span title="Modifier le nom de la frise" onClick={() => this.handleChangeStoryName(this.state.story.pk, this.state.story.version + 1)} style={{ cursor: 'pointer', borderBottom: '1px dotted #000' }}>{this.state.story.name}</span>
                    </h3>
                  </div>
                  <div className="col text-right">
                    <span onClick={() => this.handleCreateTimeline(this.state.story.pk)} className="btn btn-primary">Créer une timeline</span>
                  </div>
                </div>
              </div>

              <Table
                className="align-items-center table"
                responsive
              >
                <thead className="thead">
                  <tr>
                    <th scope="col">Titre</th>
                    <th scope="col">Chapitres</th>
                    <th scope="col">Dernière modification</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {

                    (this.state.story.timelines.length > 0) ? this.state.story.timelines.map((item, i) => {

                      return (
                        <tr key={i}>
                          <td>
                            <div>
                              <Link to={{
                                pathname: `/editor/timeline/${item.pk}/`,
                                state: {
                                  story: item,
                                }
                              }}>
                                {item.name}
                              </Link>
                            </div>
                            <div className="small text-muted">
                              Créée le {dayjs(item.created).format('DD MMMM YYYY - HH[h]mm')}
                            </div>
                          </td>
                          <td>{item.chapters.length} {item.chapters.length > 1 ? 'chapitres' : 'chapitre'}</td>
                          <td>
                            <div>{dayjs(item.updated).format('DD MMMM YYYY - HH[h]mm')}</div>
                          </td>
                          <td>
                            <UncontrolledDropdown direction="right">
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#"
                                role="button"
                                size="sm"
                                color=""
                                onClick={e => e.preventDefault()}
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem className="text-danger" onClick={e => this.handleDeleteTimeline(item, e)}>Supprimer</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })
                      : <tr>
                        <td>Aucune timeline</td>
                      </tr>
                  }
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      );
    } else {
      return <Loading />;
    }
  }

  async handleCreateTimeline(storyId) {
    let timelineName = prompt(
      "Veuillez entrer le nom de votre timeline",
      "Timeline X"
    );

    let timelineOrder = prompt(
      "Quel est l'ordre de cette timeline ?",
      1
    );

    if (timelineName == null || timelineName === "" || timelineOrder == null || timelineOrder === "") {
      alert("Veuillez renseigner un nom et un ordre");
    } else {
      try {
        await AxiosService.timeline.create(storyId, timelineName, timelineOrder)
        const story = await AxiosService.story.get(storyId)
        this.setState({
          story: story
        });
      } catch (e) {
        alert(e)
      }

    }
  }

  async handleDeleteTimeline(item, e) {

    e.stopPropagation()
    if (window.confirm("Êtes-vous sûr de vouloir supprimer la timeline " + item.name + " ?")) {
      try {
        await AxiosService.timeline.delete(item.pk)
        const result = await AxiosService.story.get(this.props.id)
        this.setState({
          story: result
        });
      } catch (e) {
        alert(e)
      }

    }

  }

  async componentDidMount() {
    try {
      const result = await AxiosService.story.get(this.props.id)
      this.setState({
        story: result
      });
    } catch (e) {

    }

  }


  async handleChangeStoryName(storyId, newVersion) {
    let newName = prompt(
      "Renommer la frise",
      ""
    );

    if (newName == null || newName === "") {
      alert("Veuillez renseigner un nom");
    } else {
      try {
        await AxiosService.story.rename(storyId, newName)
        await AxiosService.story.updateVersion(storyId, newVersion)
        const story = await AxiosService.story.get(storyId)
        this.setState({
          story: story
        });
      } catch (e) {
        alert(e)
      }

    }
  }

}

export default ViewStory;
