import React, { Component } from "react";
import StandardPage from "../Components/Layout/Dashboard/StandardPage";
import ViewTimeline from "../Components/Account/ViewTimeline";

class Account extends Component {
  render() {
    return <StandardPage component={
      <ViewTimeline id={this.props.match.params.id} 
      />
    } 
    />;
  }
}

export default Account;
