import {apiClient} from "./AxiosService"

class SoundLayerService {

    static async create(name, chapter) {
        return apiClient
        .post(
            "sound-layer/",
            { name, chapter},
        ).then(response => {
            return response.data
        })
        .catch(error => {
            throw error
        });
    }

    static async delete(id) {
        return apiClient
        .delete(
            `sound-layer/${id}/`,
            { },
        ).then(response => {
            return response.data
        })
        .catch(error => {
            throw error
        });
    }

    static async get() {
    }

    static async updateData(soundLayer, json) {
        return apiClient
        .patch(
            `sound-layer/${soundLayer.pk}/`,
            { json},
        ).then(response => {
            return response.data
        })
        .catch(error => {
            throw error
        });
    }

}

export default SoundLayerService;
