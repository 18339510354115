import React from "react";
import { Navbar, Container, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import './EditorLayout.css'

function EditorLayout(props) {
  const history = useHistory();
  
  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-dark bg-primary"
        expand="lg"
        style={{padding: '0.2rem 0.2rem'}}
      >
        <Container style={{ maxWidth: '100%', paddingLeft: '0' }}>
          <Button outline onClick={history.goBack} color="default" size="sm" type="button"><i className="fa fa-chevron-left" aria-hidden="true"></i> retour</Button>
        </Container>
      </Navbar>

      <div className="" style={{ flexGrow: 1 }}>
        {props.component}
      </div>
    </>
  )

  
}

export default EditorLayout;
