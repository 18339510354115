import React, { Component } from "react";
import StandardPage from "../Components/Layout/Dashboard/StandardPage";
import Dashboard from "../Components/Account/Dashboard";

class Account extends Component {
  render() {
    return <StandardPage title="Dashboard" component={<Dashboard/>} />;
  }
}

export default Account;
