import React from 'react';
import Loading from "../Layout/Loading";
import fabricImport from "fabric";
import Slider from "nouislider";

const fabric = fabricImport.fabric;

export default class FriseCanvas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            arrLayerCanvas: [],
            currentLayerCanvas: null,
            highestPx: 0,
            selectedLayer: null
        }

    }

    render() {
        if (this.props.selectedLayer !== null) {
            return (
                <>
                    <div
                        className="col-md-12"
                        id="storyDiv"
                        style={{
                            height: "320px"
                        }}
                    >
                        <div className="row">
                            {
                                this.props.layers.map((layer, i) => {//sort((a, b) => a.scrollRatio < b.scrollRatio)
                                    return (
                                        <div
                                            key={i}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                position: 'absolute',
                                                zIndex: this.state.currentLayerCanvas !== null && (this.state.currentLayerCanvas.layer === layer) ? '1000' : layer.scrollRatio * 100,
                                                opacity: (localStorage.getItem("layer_" + layer.pk + "_opacity") !== null) ? parseFloat(localStorage.getItem("layer_" + layer.pk + "_opacity")) : 1
                                            }}
                                        >
                                            <canvas
                                                id={"canvas" + layer.pk}
                                                style={{
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                            />
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                    <div
                        className="col-md-12"
                        style={{
                            height: "40px"
                        }}
                    >
                        <div
                            className="row"
                            style={{
                                height: "100%",
                                backgroundColor: 'rgb(51, 51, 51)'
                            }}
                        >
                            <div
                                style={{
                                    height: "100%",
                                    width: '100%',
                                    display: "flex",
                                    alignItems: "center",
                                    // padding: '15px 15px'
                                }}
                            >
                                <div id="slider" className="slider" ref="slider1" style={{ width: '100%' }} />
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            return <Loading />;
        }

    }

    async componentDidMount() {
        // Construct array of layer+canvas
        this.props.onRef(this)
        await initCanvas(this);

        var slider1 = this.refs.slider1;
        Slider.create(slider1, {
            start: [0],
            connect: [false, false],
            step: 0.1,
            range: { min: 0, max: 100 },
        });
        slider1.noUiSlider.on('update', (values, handle, unencoded, tap, positions, noUiSlider) => this._handleSliderEvent(unencoded));
    }

    componentWillUnmount() {
        this.props.onRef(null)
    }

    getLayersDataPromises() {
        const promises = []
        this.state.arrLayerCanvas.forEach((item, i) => {
            promises.push(this.layerData(item))
        })

        return promises

    }

    layerData = (layerCanvas) => {
        return new Promise((resolve, reject) => {
            let canvas = layerCanvas.canvas
            let layer = layerCanvas.layer

            let jsonValue = JSON.stringify(canvas.toJSON(['canMoveOutside', 'lockMovementX', 'lockMovementY']))

            let highestPx = this.state.highestPx
            let canvasForExport = new fabric.StaticCanvas(null);

            canvasForExport.loadFromJSON(jsonValue, function () {

                canvasForExport.forEachObject(function (obj) { obj.opacity = 1 });

                canvasForExport.setHeight(640, { backstoreOnly: false });

                if (layer.main) {
                    let width = highestPx
                    canvasForExport.setWidth(width, { backstoreOnly: false });
                } else {
                    let width = highestPx * layer.scrollRatio
                    canvasForExport.setWidth(width, { backstoreOnly: false });

                }
                canvasForExport.renderAll()

                let originalSvgValue = canvasForExport.toSVG({
                    height: "100%",
                })

                const parser = new DOMParser();
                const xml = parser.parseFromString(originalSvgValue, 'image/svg+xml');

                let xmlSVG = xml.getElementsByTagName('svg')[0]
                xmlSVG.removeAttribute('width')
                xmlSVG.setAttribute("preserveAspectRatio", "xMinYMid meet")

                var s = new XMLSerializer();
                var modifiedSVG = s.serializeToString(xmlSVG);

                var finalHTMLCode = "<html><style type=\"text/css\">body{margin:0;}</style><body></body>" + modifiedSVG + "</body></html>"
                let data = {
                    layer: layerCanvas.layer,
                    json: jsonValue,
                    svg: finalHTMLCode,
                    scrollRatio: layer.scrollRatio,
                    name: layer.name
                }

                resolve(data)

            })
        })
    }

    async componentDidUpdate(previousProps, previousState) {
        // If we have changed layer in relative component, we change the currentLayerCanvas according to it
        if (this.props.selectedLayer !== this.state.currentLayerCanvas.layer) {
            let selectedLayerCanvas = this.state.arrLayerCanvas.find(layerCanvas => layerCanvas.layer === this.props.selectedLayer)

            this.state.currentLayerCanvas.canvas.discardActiveObject().renderAll()
            this.setState({
                currentLayerCanvas: selectedLayerCanvas
            })
        }


        if (this.state.arrLayerCanvas.length !== this.props.layers.length) {
            await initCanvas(this);
        }
    }

    _handleDrop(e) {

        // handleDrop method is responsible for creating a new fabric Image and add it to the current canvas

        if (e.stopPropagation) {
            e.stopPropagation();
        }

        let currentCanvas = this.state.currentLayerCanvas.canvas
        // let currentLayer = this.state.currentLayerCanvas.layer

        let pointer = currentCanvas.getPointer(e, true);

        let img = document.querySelector('img.img_dragging');

        let posX = pointer.x - currentCanvas.viewportTransform[4];
        let posY = pointer.y;

        let width = img.naturalWidth
        let height = img.naturalHeight

        let newImage = new fabric.Image(img, {
            width: width,
            height: height,
            lockMovementY: false,
            lockMovementX: false,
            left: posX,
            top: posY,
            hasRotatingPoint: true,
            objectCaching: false,
            statefullCache: false,
            noScaleCache: true,
            canMoveOutside: false,
            // layer: currentLayer,
            crossOrigin: 'anonymous',
            opacity: 1
        });

        // If the image has a height bigger than the canvas, we resize it to fit in
        if (height > currentCanvas.height) {
            newImage.scaleToHeight(currentCanvas.height)
            newImage.top = 0
        }

        newImage.toObject = (function (toObject) {
            return function () {
                return fabric.util.object.extend(toObject.call(this), {
                    canMoveOutside: this.canMoveOutside,
                    //layer: this.layer,
                    crossOrigin: 'anonymous',
                });
            };
        })(newImage.toObject);

        currentCanvas.add(newImage);

        this._setHighestPx();
        this.props.onUpdateArrLayerCanvas(this.state.arrLayerCanvas)
    }

    _setHighestPx = () => {
        let highest = 0
        this.state.arrLayerCanvas.forEach(layerCanvas => {
            let layer = layerCanvas.layer
            let scrollRatio = layer.scrollRatio
            let canvas = layerCanvas.canvas

            let objects = canvas.getObjects()

            let maxTrX = Math.max.apply(Math, objects.map(function (o) { return o.aCoords.tr.x / scrollRatio }))

            if (highest < maxTrX) {
                highest = maxTrX
            }

        })

        let divW = document.getElementById('storyDiv').clientWidth * 2;
        if (highest < divW) {
            highest = divW

        }

        this.setState({
            highestPx: highest
        })
    }

    _handleWheel(e) {

        // Stop the scroll event to avoid scrolling the entire webpage
        e.preventDefault()
        e.stopPropagation()

        let highestPx = this.state.highestPx

        let scrollMode = "sliderMode"
        // We check if we are in "slider mode" or in "free mode"

        let mainLayerCanvas = this.state.arrLayerCanvas.find(layerCanvas => layerCanvas.layer.main === true)
        let layer = mainLayerCanvas.layer
        let canvas = mainLayerCanvas.canvas

        let divW = document.getElementById('storyDiv').clientWidth * 2;

        let trCanvas = (-canvas.viewportTransform[4] + divW) / layer.scrollRatio

        if (trCanvas >= highestPx) {
            scrollMode = "freeMode"
        }

        if (scrollMode === "sliderMode") {
            const sliderPosition = parseFloat(this.refs.slider1.noUiSlider.get());
            // We move the slider input according to wheelDelta
            const scrollDelta = (e.wheelDelta < 0) ? 2 : -2

            // sliderPosition must be between 0 and 100
            let newValue = sliderPosition + scrollDelta
            newValue = (newValue < 0) ? 0 : newValue
            this.refs.slider1.noUiSlider.set(newValue);

            console.log("handleWheel slider")
            // We use the absoluteScrollCanvas method, same than in handleSliderEvent
            this._absoluteScrollCanvas(newValue)
        } else {
            console.log("handleWheel free")
            this._relativeScrollCanvas(e.wheelDelta)
        }

    }

    _handleSliderEvent = (uiValue) => {
        this._absoluteScrollCanvas(uiValue)
    }

    // This method is used by the slider, and by the mouse wheel event if we didn't scroll past the highestPx
    _absoluteScrollCanvas(position) {
        let divW = document.getElementById('storyDiv').clientWidth * 2;
        let highestPx = this.state.highestPx

        // highestPx is always relative to the mainCanvas (we divide its value by the corresponding layer's scrollRatio)
        // To get the point to scroll : (highestPx - divW) * uiValue

        //if (highestPx > divW) {
        let scroll_grad = position / 100;

        let mainPosition = 0

        this.state.arrLayerCanvas.forEach((layerCanvas, i) => {

            let highestPxForCanvas = highestPx * layerCanvas.layer.scrollRatio

            let point = new fabric.Point((highestPxForCanvas - divW) * scroll_grad, 0)
            layerCanvas.canvas.absolutePan(point);

            if (layerCanvas.layer.main) {
                mainPosition = layerCanvas.canvas.viewportTransform[4]
            }
        })
        this.props.onPreviewScroll(mainPosition)
    }

    _relativeScrollCanvas(delta) {

        let mainPosition = 0

        this.state.arrLayerCanvas.forEach((layerCanvas, i) => {
            let move = delta * layerCanvas.layer.scrollRatio
            let canvas = layerCanvas.canvas
            if (canvas.viewportTransform[4] + move > 0) {
                let point = new fabric.Point(0, 0)
                layerCanvas.canvas.relativePan(point);
            } else {
                let point = new fabric.Point(delta * layerCanvas.layer.scrollRatio, 0)
                layerCanvas.canvas.relativePan(point);
            }
            canvas.renderAll()

            if (layerCanvas.layer.main) {
                mainPosition = layerCanvas.canvas.viewportTransform[4]
            }
        })

        this.props.onPreviewScroll(mainPosition)
    }

    _didUpdateCanvas(previousProps, previousState) {
        this.props.onUpdateArrLayerCanvas(this.state.arrLayerCanvas)
    }

    _debugHandleUpdateSoundManager(arrObj) {

        if (this.state.currentLayerCanvas) {

            let mainCanvas = this.state.arrLayerCanvas.find(element => element.layer.main === true).canvas
            //console.log(arrObj)
            arrObj.forEach(element => {

                // Width of the story div
                //let previewWidth = document.getElementById('storyDiv').clientWidth * 2
                let previewHeight = document.getElementById('storyDiv').clientHeight * 2

                let canvasHeight = 39

                let convertedX = element.x * previewHeight / canvasHeight
                let convertedW = element.width * previewHeight / canvasHeight

                // console.log("originalX : " + element.x)
                // console.log("originalW : " + element.width)
                // console.log("element.canvasHeight : " + canvasHeight)
                // console.log("previewHeight : " + previewHeight)
                // console.log("convertedX : " + convertedX)
                // console.log("convertedW : " + convertedW)

                var rect = new fabric.Rect({
                    left: convertedX,
                    top: 50,
                    width: convertedW,
                    height: 50,
                    fill: "green",
                });

                mainCanvas.add(rect)

            })

            mainCanvas.renderAll()
        }

    }



}

const initCanvas = async function (self) {

    let tmpArr = []
    self.props.layers.forEach((layer, i) => {
        //console.log(layer)
        // Foreach layer of the chapter, we create a fabric canvas

        // We get back the previously created HTML canvas in our render() method
        let canvas = new fabric.Canvas("canvas" + layer.pk);

        // We set the height and width to x2 with backstoreOnly to false, this allow us to show a 640px canvas into a 320px div
        let h = document.getElementById('storyDiv').clientHeight * 2;
        let w = document.getElementById('storyDiv').clientWidth * 2;


        canvas.setHeight(h, { backstoreOnly: false });
        canvas.setWidth(w, { backstoreOnly: false });

        // Canvas general configuration
        canvas.allowTouchScrolling = true
        canvas.preserveObjectStacking = true

        // We then load the fabric json in our canvas if we have it
        let json = layer.json;
        if (json !== null && json !== "") {
            canvas = canvas.loadFromJSON(json, function () {
                // console.log("did load json")
                self._setHighestPx()
                self._didUpdateCanvas()

                // Lock movement if layer is locked
                const lockedValue = localStorage.getItem("layer_" + layer.pk + "_lock")
                if (lockedValue != null) {
                    const boolLockedValue = (lockedValue === 'true')
                    canvas.forEachObject(obj => {
                        obj.selectable = !boolLockedValue
                        obj.lockMovementX = boolLockedValue
                        obj.lockMovementY = boolLockedValue
                    })
                }

                // Debugging sounds positions on canvas
                // if (self.props.debugSounds) {
                //     console.log(self.props.debugSounds)
                //     self._debugHandleUpdateSoundManager(self.props.debugSounds)
                // }
                // END DEBUG

            });
        }

        canvas.on("object:selected", obj => {
            self.props.onSelectObject(obj, canvas)
        });

        canvas.on("drop", obj => {
            self._handleDrop(obj.e)
            self.props.onSaveNeeded()
        });

        canvas.on("selection:cleared", obj => {
            self.props.onDeselectObject(obj, canvas)
        });

        canvas.on("selection:updated", obj => {
            self.props.onSelectObject(obj, canvas)
        });

        // var left1 = 0;
        // var top1 = 0;
        // var scale1x = 0;
        // var scale1y = 0;
        // var width1 = 0;
        // var height1 = 0;
        // canvas.on('object:scaling', function (e) {
        //     var obj = e.target;
        //     if (!obj.canMoveOutside) {
        //         obj.setCoords();
        //         var br = obj.getBoundingRect();

        //         if (((br.height + br.top) >= obj.canvas.height) || (br.top < 0)) {
        //             obj.left = left1
        //             obj.top = top1
        //             obj.scaleX = scale1x
        //             obj.scaleY = scale1y
        //             obj.width = width1
        //             obj.height = height1

        //         }
        //         else {
        //             left1 = obj.left
        //             top1 = obj.top
        //             scale1x = obj.scaleX
        //             scale1y = obj.scaleY
        //             width1 = obj.width
        //             height1 = obj.height
        //         }
        //     }
        // });

        canvas.on("object:moving", function (e) {
            //console.log("moving")
            let obj = e.target;

            // If we declared canMoveOutside to false, we avoid the image to get farther than canvas
            if (!obj.canMoveOutside) {

                // if object is too big ignore
                if (
                    obj.currentHeight > obj.canvas.height ||
                    obj.currentWidth > obj.canvas.width
                ) {
                    return;
                }
                obj.setCoords();

                let br = obj.getBoundingRect()
                // top
                if (br.top < 0) {
                    obj.top = Math.max(obj.top, obj.top - br.top);
                }
                // bottom
                if ((br.top + br.height > obj.canvas.height) || (br.left + br.width > obj.canvas.width)) {
                    obj.top = Math.min(
                        obj.top,
                        obj.canvas.height - br.height + obj.top - br.top
                    );
                }
            }

            // When an object is moving on a canvas where the layer has a scrollRatio != 1
            // We have to the canvas to reflect where it will appear on drop

            // If we are on main layer, the position in the div in the same as the position we want in canvas
            // TODO : rename lastLeft to something more accurate
            if (!layer.main) {

                let lastLeft
                if (self.state.lastLeft) {
                    lastLeft = self.state.lastLeft
                } else {
                    lastLeft = e.transform.original.left
                }
                let move = e.target.left - lastLeft

                obj.left += move * layer.scrollRatio
                self._relativeScrollCanvas(-move)

                self.setState({
                    lastLeft: e.target.left,
                })


                // We move the slider input according to the panning

                let slider = document.getElementById('slider');

                let pos = -canvas.viewportTransform[4] * 100 / self.state.highestPx
                slider.value = pos

            }

        });

        canvas.on("object:moved", function (e) {
            self.setState({
                lastLeft: null,
                lastLeftOver: null,
            })
            self.props.onSaveNeeded()
        })

        canvas.on('object:modified', function (options) {
            self.props.onSaveNeeded()
        })

        canvas.on('mouse:up', function (opt) {
            self._setHighestPx()
            self._didUpdateCanvas()
        });

        canvas.on('mouse:wheel', function (opt) {
            self._handleWheel(opt.e)
        });

        canvas.on('dragover', function (opt) {
            let e = opt.e
            if (!layer.main) {
                //console.log("dragover")
                let lastLeftOver
                if (self.state.lastLeftOver) {
                    lastLeftOver = self.state.lastLeftOver
                } else {
                    lastLeftOver = 0
                }
                let move = e.layerX - lastLeftOver

                self._relativeScrollCanvas(-move)

                self.setState({
                    lastLeftOver: e.layerX,
                })
            }
        });

        canvas.on('dragleave', function (opt) {
            self.setState({
                lastLeftOver: null,
            })
        });


        // We link the canvas and the layer in an object, and we add it to a tmp array
        let layerCanvas = {
            layer: layer,
            canvas: canvas
        }

        tmpArr.push(layerCanvas)

        if (self.props.selectedLayer === layer) {
            self.setState({
                currentLayerCanvas: layerCanvas,
            })
        }

    })

    // Our temp array is set in the state
    self.setState({
        arrLayerCanvas: tmpArr
    })

    self.props.onSetArrLayerCanvas(tmpArr)

};