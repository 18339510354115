import React from 'react'
import { Button } from 'reactstrap'

export default class AddSoundLayerRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div
                style={{
                    height: "50px",
                    display: 'grid',
                    gridTemplateColumns: '0.4fr 1.6fr'
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: '15px',
                        borderRight: "1px solid #232323"
                    }}
                >
                    <Button
                        size="sm"
                        color="primary"
                        onClick={(id, json) => this.props.onCreateLayer()}
                    >
                        Nouveau layer son <i className="fa fa-volume-up" aria-hidden="true"></i>
                    </Button>

                </div>

            </div>

        )
    }

    async componentDidMount() {

    }

    componentDidUpdate() {

    }

}
