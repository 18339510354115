import React from 'react'
import LayerRow from '../LayerRow'
import SoundLayerRow from '../SoundLayerRow'
import AddLayerRow from './AddLayerRow';
import AddSoundLayerRow from './AddSoundLayerRow';

export default class LayerList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wheelDelta: null
        }

        this.arrLayerRow = []
        this.arrSoundRow = []

        this._onScrollTimeline = this._onScrollTimeline.bind(this)
    }

    render() {
        return (
            <div
                style={{
                    backgroundColor: "#333",
                }}
            >
                <div>
                    {/* Add layer row */}
                    <AddLayerRow
                        onCreateLayer={this.props.onCreateLayer}
                    />

                    {/* LayerRows */}
                    {[].concat(this.props.arrLayerCanvas)
                        .sort((a, b) => a.layer.scrollRatio - b.layer.scrollRatio)
                        .map((layerCanvas, i) => {

                            return (
                                <LayerRow
                                    layer={layerCanvas.layer}
                                    canvas={layerCanvas.canvas}
                                    selectedLayer={this.props.selectedLayer}
                                    key={i}
                                    wheelDelta={this.state.wheelDelta}
                                    onSelectLayer={this.props.onSelectLayer}
                                    onChangeScrollRatio={this.props.onChangeScrollRatio}
                                    onChangeName={this.props.onChangeName}
                                    onChangeOpacity={this.props.onChangeOpacity}
                                    onChangeLock={this.props.onChangeLock}
                                    onDeleteLayer={this.props.onDeleteLayer}

                                    onScrollTimeline={this._onScrollTimeline}

                                    onRef={ref => { this.arrLayerRow.push(ref) }}

                                />
                            )
                        })}

                    {/* SoundLayerRows */}

                    {[].concat(this.props.soundLayers)
                        .map((soundLayer, i) => {
                            return (
                                <SoundLayerRow
                                    soundLayer={soundLayer}
                                    key={i}
                                    onDeleteLayer={this.props.onDeleteSoundLayer}
                                    onRef={ref => { this.arrSoundRow.push(ref) }}
                                    wheelDelta={this.state.wheelDelta}
                                    onScrollTimeline={this._onScrollTimeline}
                                    onSaveNeeded={this.props.onSaveNeeded}
                                    handleUpdateSoundManager={arrObj => { this.props.handleUpdateSoundManager(arrObj) }}
                                    handleDeleteObjectInSoundManager={uniqueID => { this.props.handleDeleteObjectInSoundManager(uniqueID) }}

                                />
                            )
                        })}

                    {/* Add layer row */}

                    <AddSoundLayerRow
                        onCreateLayer={this.props.onCreateSoundLayer}
                    />

                </div>


                {/* Save button */}
                {/* <div
                    className="row"
                    style={{
                        height: "10%",
                    }}
                >
                    <div
                        className="col-md-2"
                        style={{
                            height: "100%",
                        }}
                    >
                        <button
                            className="btn"
                            onClick={(id, json) => this.props.onSave()}
                            style={{
                                height: "100%",
                                backgroundColor: "#eec165",
                                color: "black"
                            }}
                        >
                            Sauvegarder
                            </button>
                    </div>
                </div> */}

            </div>

        )
    }

    async componentDidMount() {
        this.props.onRef(this)
    }

    componentDidUpdate() {
        //this.arrLayerRow = []
    }

    _onScrollTimeline(delta) {

        this.setState({
            wheelDelta: delta
        })
    }

    getTriggersDataPromises() {
        const promises = []

        this.arrLayerRow.forEach((layerRow, i) => {
            let triggersPromise = layerRow.triggersPromise()
            promises.push(triggersPromise)
        })

        return promises

    }

    getSoundsDataPromises() {
        const promises = []
        this.arrSoundRow.forEach((soundRow, i) => {
            let soundsPromise = soundRow.soundsPromise()
            promises.push(soundsPromise)
        })

        return promises

    }

}
