import React, { Component } from "react";

import ChapterEditor from "../Components/ChapterEditor/ChapterEditor";
import EditorLayout from "../Components/Layout/EditorLayout";

class Account extends Component {
  render() {
    return <EditorLayout component={
      <ChapterEditor
        id={this.props.match.params.id} />} />;
  }
}

export default Account;