import React from 'react';

import AxiosService from '../../Api/AxiosService'

import Dropzone from 'react-dropzone'

export default class FriseSounds extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isVisible: false,
        }
    }

    render() {
        return (
            <>
                {/* Folders and images if expanded */}
                {
                    this.state.currentFolder &&
                    <div className={'container dock-gallery ' + (this.props.isVisible ? 'dock-gallery-extended' : '')} style={{ height: '100vh', overflowY: 'scroll' }}>
                        <div className="row mb-2">
                            {this.state.currentFolder &&
                                <div className="col-md-12">
                                    <Dropzone accept={'image/*'} onDrop={acceptedFiles => this.manageDroppedFile(acceptedFiles, this.state.currentFolder)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}
                                                    style={{
                                                        backgroundColor: '#8787fd',
                                                        alignItems: 'center',
                                                        color: '#fff',
                                                        padding: 15,
                                                        fontSize: '1rem'
                                                    }}
                                                >
                                                    <input {...getInputProps()} />
                                                    <span><i className="fa fa-cloud-upload" aria-hidden="true"></i> Drag&amp;drop ou cliquez pour upload un son</span>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                            }
                        </div>

                        <div className="row mb-2">
                            <div className="col-md-12">
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={(id, json) => this._handleCreateFolder(this.state.currentFolder)}
                                >
                                    <i className="fa fa-plus" aria-hidden="true"></i> Créer un dossier
                                </button>
                            </div>
                        </div>

                        <div className="row mb-2">
                            {/* Back button if we are in a folder */}
                            {
                                this.state.currentFolder.parentFolder &&
                                <div className="col-md-12">
                                    <button
                                        className="btn btn-outline-secondary btn-sm"
                                        onClick={(e) => this._handleBackToParentFolder(this.state.currentFolder.parentFolder)}
                                    >
                                        Retour
                                    </button>
                                </div>
                            }

                            {/* Subfolders */}
                            {
                                this.state.currentFolder.subfolders.map((folder, i) => {
                                    return (
                                        <div className="col-md-12" key={folder.id}
                                        >
                                            <div
                                                style={{
                                                    height: '40px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    color: '#fff'
                                                }}
                                            >
                                                <div style={{ cursor: 'pointer' }} onClick={(e) => this._handleDoubleClickFolder(folder)}>
                                                    {/* Folder icon */}
                                                    <i className="fa fa-folder" aria-hidden="true"></i>

                                                    {/* Folder name */}
                                                    <span> {folder.name}</span>
                                                </div>
                                                {/* Delete button */}
                                                <i style={{ cursor: 'pointer' }} className="fa fa-trash-o" aria-hidden="true" onClick={(e) => this._handleDeleteFolder(folder)}></i>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            {/* Sounds */}
                            <div className="col-md-12">

                                {


                                    this.state.currentFolder.files.map((media, i) => {
                                        return (
                                            <div key={i} style={{ marginBottom: '5px' }}>

                                                {/* Sound icon */}

                                                    <img
                                                        style={{
                                                            height: "30px",
                                                            cursor: 'grab'
                                                        }}
                                                        src={require("../../assets/home/img/music-logo.png")}
                                                        key={media.id}
                                                        alt="sound"

                                                        data-mediaoriginalname={media.originalName}
                                                        data-mediaid={media.id}
                                                        data-mediaurl={AxiosService.media.getMediaUrl(media.file)}

                                                        draggable="true"
                                                        onDragStart={(e) =>
                                                            this._handleSoundDragStart(e)
                                                        }
                                                        onDragEnd={(e) =>
                                                            this._handleSoundDragEnd(e)
                                                        }
                                                        onClick={(e) =>
                                                            this._handleSimpleClickMedia(media)
                                                        }
                                                    />

                                                {/* Folder name */}
                                                <span style={{color: '#fff'}}>&nbsp;{media.originalName}</span>


                                            </div>
                                        )
                                    })




                                }

                            </div>



                        </div>
                    </div>

                }
            </>

        )
    }

    async componentDidMount() {

        let rootFolderQuery = this.props.folder.filter((e) => e.parentFolder === null)
        if (rootFolderQuery.length === 1) {
            let rootFolder = rootFolderQuery[0]
            this.setState({
                currentFolder: rootFolder
            });
        } else if (rootFolderQuery.length === 0) {
            alert("No root folder, this is not normal.")
        } else {
            alert("Multiple root folder, this is not normal.")
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.isVisible !== this.props.isVisible) {
            this.setState({ isVisible: nextProps.isVisible });
        }
    }

    async componentDidUpdate(previousProps, previousState) {
        // These two count are used to check if we have new files on update

        let previousCount = previousProps.folder
            .map((folder) => {
                return folder.files.length
            }).reduce((sum, length) => {
                return sum + length;
            });

        let count = this.props.folder
            .map((folder) => {
                return folder.files.length
            }).reduce((sum, length) => {
                return sum + length;
            });

        // If we have a new folder or new files, we update the state to reload the component

        if ((previousProps.folder.length !== this.props.folder.length) || (previousCount !== count)) {

            // These lines are used to get back the currentFolder when we reload the component
            let previousCurrentFolder = previousState.currentFolder
            let newCurrentFolderQuery = this.props.folder.filter((e) => e.id === previousCurrentFolder.id)
            let newCurrentFolder = newCurrentFolderQuery[0]

            this.setState({
                currentFolder: newCurrentFolder,
            });

        }

    }

    _handleSoundDragStart(e) {
        e.target.classList.add("sound_dragging")
    }

    _handleSoundDragEnd(e) {
        e.target.classList.remove("sound_dragging")
    }

    _handleDoubleClickFolder(folder) {
        this.setState({
            currentFolder: folder,
        });
    }

    _handleSimpleClickMedia(media) {
        this.setState({
            clickedMedia: media
        })
    }

    _handleDeleteFolder = async folder => {
        if (window.confirm(`Êtes-vous sûr de vouloir supprimer ce dossier ? Cette action est irreversible.`)) {
            await AxiosService.folder.delete(folder.id)
            this.props.onUpdateSoundLibrary()
        }
    }

    async _handleDeleteMedia(media) {
        await AxiosService.media.delete(media.id)
        this.props.onUpdateSoundLibrary()
    }

    _handleBackToParentFolder(parentFolderId) {

        let folder = this.props.folder.find((folder) => {
            return folder.id === parentFolderId;
        })

        this.setState({
            currentFolder: folder,
        });
    }

    async _handleCreateFolder(parentFolder) {

        let folderName = prompt(
            "Veuillez entrer le nom de votre dossier",
            ""
        );

        if (folderName == null || folderName === "") {
            alert("Veuillez renseigner un nom");
        } else {
            try {
                await AxiosService.folder.create(parentFolder.id, folderName, 2)

                this.props.onUpdateSoundLibrary()
            } catch (e) {
                alert(e)
            }
        }

    }

    async manageDroppedFile(droppedFile, folder) {
        const promises = []
        droppedFile.forEach(file => {
            promises.push(this.sendRequest(file, folder))
        })
        try {
            await Promise.all(promises);
            this.props.onUpdateSoundLibrary()
        } catch (e) {
            alert(e)
        }

    }

    sendRequest(file, folder) {
        return new Promise((resolve, reject) => {

            resolve(AxiosService.media.post(file, folder.id))
        });
    }

}



