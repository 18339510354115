import React from 'react'

import AxiosService from "../../Api/AxiosService"

import Loading from "../Layout/Loading"

import FriseCanvas from "./FriseCanvas"
import LayerList from "./Layers/LayerList/LayerList"
import FriseGallery from "./FriseGallery"
import FriseSounds from "./FriseSounds"
import FriseTriggers from "./FriseTriggers"

import "../Account/viewchapter.css";
import SoundManager from './SoundManager'
import ObjectConfiguration from "../Editor/ObjectConfiguration"

export default class ChapterEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chapter: null,
            timeline: null,
            story: null,
            selectedLayer: null,
            selectedObject: null,
            selectedCanvas: null,

            dockImagesVisibility: false,
            dockSoundsVisibility: false,
            dockTriggersVisibility: false,

            debugSounds: []
        }

        this._handleSelectLayer = this._handleSelectLayer.bind(this)
        this._handleCreateLayer = this._handleCreateLayer.bind(this)
        this._handleDeleteLayer = this._handleDeleteLayer.bind(this)
        this._handleSelectObject = this._handleSelectObject.bind(this)
        this._handleDeselectObject = this._handleDeselectObject.bind(this)
        this._handleUpdateImageLibrary = this._handleUpdateImageLibrary.bind(this)
        this._handleUpdateSoundsLibrary = this._handleUpdateSoundsLibrary.bind(this)
        this._handleCreatedArrLayerCanvas = this._handleCreatedArrLayerCanvas.bind(this)
        this._onUpdateArrLayerCanvas = this._onUpdateArrLayerCanvas.bind(this)
        this._onSaveNeeded = this._onSaveNeeded.bind(this)
        this._save = this._save.bind(this)
        this._handleUpdateTriggerType = this._handleUpdateTriggerType.bind(this)
        this._handleDeleteSoundLayer = this._handleDeleteSoundLayer.bind(this)
        this._handleCreateSoundLayer = this._handleCreateSoundLayer.bind(this)

        this.onPreviewScroll = this.onPreviewScroll.bind(this)

        window.addEventListener('resize', (e) => {
            if (this.canvasChild != null) {
                for (const layerCanvas of this.canvasChild.state.arrLayerCanvas) {
                    let h = document.getElementById('storyDiv').clientHeight * 2;
                    let w = document.getElementById('storyDiv').clientWidth * 2;
                    layerCanvas.canvas.setHeight(h, { backstoreOnly: false });
                    layerCanvas.canvas.setWidth(w, { backstoreOnly: false });
                }
            }
        })
    }

    render() {
        if (this.state.story !== null) {
            return (
                <>
                    <FriseGallery
                        isVisible={this.state.dockImagesVisibility}
                        folder={this.state.gallery_folders}
                        onUpdateImageLibrary={this._handleUpdateImageLibrary}
                    />

                    <FriseSounds
                        isVisible={this.state.dockSoundsVisibility}
                        folder={this.state.sounds_folders}
                        onUpdateSoundLibrary={this._handleUpdateSoundsLibrary}
                    />

                    <FriseTriggers
                        isVisible={this.state.dockTriggersVisibility}
                        trigger_types={this.state.trigger_types}
                        onUpdateTriggerType={this._handleUpdateTriggerType}
                        onExtend={this._handleTriggersExtend}
                    />

                    <div style={{ display: 'flex' }}>

                        <div className="dock-menu">
                            <div className={'dock-item ' + (this.state.dockImagesVisibility ? 'dock-item-selected' : '')}
                                onClick={() => this.toggleDockImagesVisibility(!this.state.dockImagesVisibility)}>
                                <i className="fa fa-picture-o dock-item-img" aria-hidden="true"></i>
                                <span className="dock-item-title">Images</span>
                            </div>
                            <div className={'dock-item ' + (this.state.dockSoundsVisibility ? 'dock-item-selected' : '')}
                                onClick={() => this.toggleDockSoundsVisibility(!this.state.dockSoundsVisibility)}>
                                <i className="fa fa-music dock-item-img" aria-hidden="true"></i>
                                <span className="dock-item-title">Sons</span>
                            </div>
                            <div className={'dock-item ' + (this.state.dockTriggersVisibility ? 'dock-item-selected' : '')}
                                onClick={() => this.toggleDockTriggersVisibility(!this.state.dockTriggersVisibility)}>
                                <i className="fa fa-magnet dock-item-img" aria-hidden="true"></i>
                                <span className="dock-item-title">Triggers</span>
                            </div>
                            <SoundManager onRef={ref => { this.soundManager = ref }} />

                        </div>

                        <div className="grid-container" style={{ flexGrow: 1 }}>

                            <div className="General"
                                style={{
                                    backgroundColor: "#333333",
                                    color: "#aaacad",
                                    display: "flex",
                                    alignItems: "center",
                                    paddingTop: "2px",
                                    paddingBottom: "2px",
                                }}
                            >
                                {this.state.chapter.image !== null &&
                                    <img
                                        style={{ maxWidth: "36px", maxHeight: "36px", cursor: "pointer", marginLeft: "15px", marginRight: "20px" }}
                                        src={this.state.chapter.image === null ?
                                            require("../../assets/home/img/icon-placeholder.png") :
                                            AxiosService.media.getMediaUrl(this.state.chapter.image)}
                                        key={this.state.chapter.id}
                                        alt="chapter"
                                        className="imgComponent avatar rounded"
                                        crossOrigin="anonymous"
                                        onClick={() => this.launchImageSelection()}
                                    />
                                }
                                <input
                                    type="file"
                                    id="chapter-image-edit"
                                    style={{ display: 'none' }}
                                    onChange={(e) => this.handleChangeChapterImage(e, this.state.chapter.pk)}
                                />

                                <span
                                    className="page-title"
                                    onClick={() => this.handleChangeChapterName(this.state.chapter.pk)}
                                    style={{
                                        cursor: "pointer",
                                        fontWeight: "bold"
                                    }}
                                >
                                    {this.state.chapter.name}
                                </span>

                            </div>


                            <div className="ObjectConfiguration">

                                <div
                                    style={{
                                        backgroundColor: "#333333",
                                        color: "#aaacad",
                                        width: "100%",
                                        height: "40px"
                                    }}
                                >
                                    <ObjectConfiguration
                                        currentObject={this.state.selectedObject}
                                        selectedCanvas={this.state.selectedCanvas}
                                        onSaveNeeded={this._onSaveNeeded}
                                    />
                                </div>
                            </div>

                            <div className="Preview" style={{ backgroundColor: "#333333", padding: '15px 15px 0 15px', borderTop: "1px solid #232323", borderBottom: "1px solid #232323" }}>
                                {
                                    this.state.chapter.layers &&
                                    this.state.selectedLayer !== null &&
                                    <FriseCanvas
                                        onRef={ref => { this.canvasChild = ref }}
                                        layers={this.state.chapter.layers}
                                        selectedLayer={this.state.selectedLayer}
                                        onSelectObject={this._handleSelectObject}
                                        onDeselectObject={this._handleDeselectObject}
                                        onSetArrLayerCanvas={this._handleCreatedArrLayerCanvas}
                                        onUpdateArrLayerCanvas={this._onUpdateArrLayerCanvas}
                                        onSaveNeeded={this._onSaveNeeded}
                                        onPreviewScroll={this.onPreviewScroll}
                                        debugSounds={this.state.debugSounds}
                                    />
                                }
                            </div>

                            <div className="Layers">
                                {this.state.chapter.layers &&
                                    this.state.arrLayerCanvas &&

                                    <LayerList
                                        style={{ height: "360px" }}
                                        onRef={ref => { this.layerListChild = ref }}
                                        layers={this.state.chapter.layers}
                                        arrLayerCanvas={this.state.arrLayerCanvas}
                                        soundLayers={this.state.chapter.soundLayers}
                                        onSelectLayer={this._handleSelectLayer}
                                        selectedLayer={this.state.selectedLayer}
                                        onChangeScrollRatio={this._handleChangeLayerScrollRatio}
                                        onChangeName={this._handleChangeLayerName}
                                        onChangeOpacity={this._handleChangeLayerOpacity}
                                        onCreateLayer={this._handleCreateLayer}
                                        onDeleteLayer={this._handleDeleteLayer}
                                        onDeleteSoundLayer={this._handleDeleteSoundLayer}
                                        onCreateSoundLayer={this._handleCreateSoundLayer}
                                        onChangeLock={this._handleChangeLayerLock}
                                        onSave={(e) => this._save(true)}
                                        onSaveNeeded={this._onSaveNeeded}

                                        handleUpdateSoundManager={arrObj => { this._handleUpdateSoundManager(arrObj) }}
                                        handleDeleteObjectInSoundManager={uniqueID => { this._handleDeleteObjectInSoundManager(uniqueID) }}

                                    />

                                }
                            </div>
                        </div>
                    </div>

                </>
            )
        } else {
            return <Loading />;
        }
    }

    async componentDidMount() {
        // On did mount, we call the WS to get user's imageLibrary, current timeline and current chapter

        const chapter = await AxiosService.chapter.get(this.props.id);
        const timeline = await AxiosService.timeline.get(chapter.timeline);
        const story = await AxiosService.story.get(timeline.story)
        const gallery_folders = await AxiosService.folder.get(2);
        const sounds_folders = await AxiosService.folder.get(3);
        const trigger_types = await AxiosService.triggerType.get();

        // Auto select main layer
        chapter.layers.forEach(layer => {
            if (layer.main) {
                this._handleSelectLayer(layer)
            }
        })

        this.setState({
            chapter: chapter,
            timeline: timeline,
            story: story,
            gallery_folders: gallery_folders,
            sounds_folders: sounds_folders,
            trigger_types: trigger_types
        });
    }

    toggleDockImagesVisibility = (value) => {
        this.setState({
            dockImagesVisibility: value,
            dockSoundsVisibility: false,
            dockTriggersVisibility: false,
        })
    }

    toggleDockSoundsVisibility = (value) => {
        this.setState({
            dockSoundsVisibility: value,
            dockImagesVisibility: false,
            dockTriggersVisibility: false,
        })
    }

    toggleDockTriggersVisibility = (value) => {
        this.setState({
            dockTriggersVisibility: value,
            dockImagesVisibility: false,
            dockSoundsVisibility: false,
        })
    }

    _handleImgDragStart(e) {
        e.target.classList.add("img_dragging")
    }

    _handleImgDragEnd(e) {
        e.target.classList.remove("img_dragging")
    }

    async _handleUpdateImageLibrary() {
        // We added some images, we have to call the api and update the state

        const gallery_folders = await AxiosService.folder.get(2);
        this.setState({
            gallery_folders: gallery_folders,
        });
    }

    async _handleUpdateTriggerType() {
        // We added or deleted some trigger_type, we have to call the api and update the state

        const trigger_types = await AxiosService.triggerType.get();
        this.setState({
            trigger_types: trigger_types,
        });
    }

    async _handleUpdateSoundsLibrary() {
        // We added some images, we have to call the api and update the state

        const sounds_folders = await AxiosService.folder.get(3);
        this.setState({
            sounds_folders: sounds_folders,
        });
    }

    _handleSelectLayer(layer) {
        // User choose a layer in FriseLayers component
        // We change the state to dispatch the information to other components

        this.setState({
            selectedLayer: layer
        })

    }

    _handleSelectObject(object, canvas) {
        // User choose an object in a canvas. 
        // We change the state to dispatch the information to other component

        this.setState({
            selectedObject: object,
            selectedCanvas: canvas
        })
    }

    _handleDeselectObject(object, canvas) {
        // User deselected an object in a canvas. 
        // We change the state to dispatch the information to other component

        this.setState({
            selectedObject: null,
            selectedCanvas: null
        })
    }


    _handleChangeLayerName = async (affectedLayer, newName) => {
        await this.setState(state => {
            const layers = state.chapter.layers.map((layer, i) => {
                if (layer === affectedLayer) {
                    layer.name = newName
                }
                return layer
            })
            return {
                layers
            }
        })
        this._save(false)
    }

    _handleChangeLayerScrollRatio = async (affectedLayer, newScrollRatio) => {
        // User has changed the scrollRatio of a layer
        // We change the state to dispatch the information to other components

        await this.setState(state => {
            const layers = state.chapter.layers.map((layer, i) => {
                if (layer === affectedLayer) {
                    layer.scrollRatio = newScrollRatio
                }
                return layer
            })
            return {
                layers
            }
        })
        this._save(false)
    }

    async _handleCreateLayer() {
        let layerName = prompt(
            "Veuillez entrer le nom de votre layer",
            "Layer X"
        );

        let layerScrollRatio = prompt(
            "Veuillez entrer le scrollRatio de votre layer",
            2.0
        );

        if (layerName == null || layerName === "" || layerScrollRatio === null) {
            alert("Veuillez renseigner un nom");
        } else {
            try {
                const chapterId = this.state.chapter.pk
                await AxiosService.layer.create(layerName, layerScrollRatio, chapterId)
                const chapter = await AxiosService.chapter.get(chapterId)

                this.setState({
                    chapter: chapter,
                    currentLayer: null
                });

            } catch (e) {
                alert(e.response.request.response)
            }

        }
    }

    async _handleCreateSoundLayer() {
        let layerName = prompt(
            "Veuillez entrer le nom de votre sound layer",
            "Sound X"
        );

        if (layerName == null || layerName === "") {
            alert("Veuillez renseigner un nom");
        } else {
            try {
                const chapterId = this.state.chapter.pk
                await AxiosService.soundLayer.create(layerName, chapterId)
                const chapter = await AxiosService.chapter.get(chapterId)

                this.setState({
                    chapter: chapter,
                    currentLayer: null
                });

            } catch (e) {
                alert(e)
            }

        }
    }

    async _handleDeleteLayer(layer) {

        try {
            const chapterId = this.state.chapter.pk
            await AxiosService.layer.delete(layer.pk)
            const chapter = await AxiosService.chapter.get(chapterId)

            this.setState({
                chapter: chapter,
                currentLayer: null
            });

        } catch (e) {
            alert(e)
        }

    }

    async _handleDeleteSoundLayer(soundLayer) {

        try {
            const chapterId = this.state.chapter.pk
            await AxiosService.soundLayer.delete(soundLayer.pk)
            const chapter = await AxiosService.chapter.get(chapterId)

            this.setState({
                chapter: chapter,
                currentLayer: null
            });

        } catch (e) {
            alert(e)
        }

    }

    _handleChangeLayerOpacity = () => {
        this.canvasChild.forceUpdate()
    }

    _handleChangeLayerLock = (layerId, isLocked) => {
        for (const layerCanvas of this.canvasChild.state.arrLayerCanvas) {
            if (layerCanvas.layer.pk === layerId) {
                layerCanvas.canvas.forEachObject(obj => {
                    obj.selectable = !isLocked
                    obj.lockMovementX = isLocked
                    obj.lockMovementY = isLocked
                })
                layerCanvas.canvas.discardActiveObject().renderAll()
            }
        }
    }

    async _save(automatic) {
        console.log("save")
        // TODO : refacto this method, the merge is ugly
        let imgPromises = this.canvasChild.getLayersDataPromises()
        let triggersPromises = this.layerListChild.getTriggersDataPromises()
        let soundsPromises = this.layerListChild.getSoundsDataPromises()

        Promise.all(imgPromises)
            .then(imgData => {
                Promise.all(triggersPromises)
                    .then(triggersData => {

                        // Merging the two arrays

                        imgData.forEach(element => {
                            let layer = element.layer
                            let filtered = triggersData.filter(data => data.layer.pk === layer.pk)

                            if (filtered.length === 1) {
                                element.jsonTriggers = filtered[0].jsonTriggers

                            } else {
                                console.log('Error getting triggerData for imgData')
                            }

                        });

                        Promise.all(soundsPromises)
                            .then(soundsData => {
                                this._sendSoundLayersData(soundsData)
                            })

                        this._sendLayersData(imgData)
                        this._updateStoryVersion()
                        if (automatic) {
                            alert("Sauvegarde OK")
                        }

                    })
                    .catch(err => console.log('There was an error resolving triggers promises:' + err))


            })
            .catch(err => console.log('There was an error resolving layers promises:' + err))

    }

    async _updateStoryVersion() {
        let newVersion = this.state.story.version + 1
        await AxiosService.story.updateVersion(this.state.story.pk, newVersion)
        this.state.story.version = newVersion
    }

    async _sendLayersData(layersData) {

        await Promise.all(layersData.map(async (item) => {
            await AxiosService.layer.updateData(item.layer, item.json, item.svg, item.jsonTriggers, item.scrollRatio, item.name)
        }));

    }

    async _sendSoundLayersData(soundLayersData) {

        await Promise.all(soundLayersData.map(async (item) => {
            await AxiosService.soundLayer.updateData(item.layer, item.jsonSounds)
        }));
        //alert("Les layers sons du chapitre ont été sauvegardés !")
    }



    _handleCreatedArrLayerCanvas(arr) {
        this.setState({
            arrLayerCanvas: arr
        });
    }

    _onUpdateArrLayerCanvas(arr) {

        this.setState({
            arrLayerCanvas: arr
        });
    }

    _onSaveNeeded() {
        this._save(false)
    }


    onPreviewScroll(position) {
        // Inform the soundManager of our position on the preview
        this.soundManager.onPreviewScroll(position)
    }

    async handleChangeChapterName(chapterId) {

        let newName = prompt(
            "Renommer le chapitre",
            ""
        );

        if (newName == null || newName === "") {
            alert("Veuillez renseigner un nom");
        } else {
            try {
                await AxiosService.chapter.rename(chapterId, newName)
                const chapter = await AxiosService.chapter.get(chapterId)
                await this._updateStoryVersion()
                this.setState({
                    chapter: chapter
                });
            } catch (e) {
                alert(e)
            }

        }
    }

    launchImageSelection() {
        document.getElementById("chapter-image-edit").click()
    }

    async handleChangeChapterImage(event, chapterId) {

        let image = event.target.files[0]

        await AxiosService.chapter.changeImage(chapterId, image)
        const chapter = await AxiosService.chapter.get(chapterId)
        await this._updateStoryVersion()
        this.setState({
            chapter: chapter
        });
    }

    _handleUpdateSoundManager(arrObj) {
        this.soundManager.updateAudio(arrObj)

        // Debugging sound placements
        // console.log("canvasChild")
        // console.log(this.canvasChild)
        // this.canvasChild._debugHandleUpdateSoundManager(arrObj)


        this.setState({
            debugSounds: arrObj
        })

    }

    _handleDeleteObjectInSoundManager(uniqueID) {
        this.soundManager.deleteObject(uniqueID)
    }
}
