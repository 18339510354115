import React from 'react';
import fabricImport from "fabric";

const fabric = fabricImport.fabric;

export default class LayerTimeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (

            <div
                id={"canvas-container" + this.props.layer.pk}
                key={this.props.key}
                style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor:"#606060"
                }}
            >
                <canvas
                    id={"canvas-timeline" + this.props.layer.pk}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                >

                </canvas>
            </div>

        )
    }

    async componentDidMount() {
        await initImageCanvas(this)

        document.getElementById("canvas-container" + this.props.layer.pk).addEventListener('wheel', event => {
            this.handleScroll(event)
        })
    }

    async componentDidUpdate(previousProps, previousState) {

        let previousJSON = JSON.stringify(this.state.timelineCanvas.toJSON(['layer', 'canMoveOutside']))
        let newJSON = JSON.stringify(this.props.canvas.toJSON(['layer', 'canMoveOutside']))

        // If JSON has changed, update the canvas
        if (previousJSON !== newJSON) {
            await updateImageCanvas(this)
        }

        this._relativeScrollCanvas(this.props.wheelDelta)

    }

    _relativeScrollCanvas(delta) {
        let canvas = this.state.timelineCanvas

        let move = delta * this.props.layer.scrollRatio

        if (canvas.viewportTransform[4] + move > 0) {
            let point = new fabric.Point(0, 0)
            canvas.absolutePan(point)
        } else {
            let point = new fabric.Point(delta * this.props.layer.scrollRatio, 0)
            canvas.relativePan(point);
        }
        canvas.renderAll()

    }

    handleScroll(e) {

        // Stop the scroll event to avoid scrolling the entire webpage
        e.preventDefault()
        e.stopPropagation()

        //this._relativeScrollCanvas(e.wheelDelta)
        this.props.onScrollTimeline(e.wheelDelta)
    }

}

const initImageCanvas = async function (self) {

    //let tmpArr = []

    // Foreach layer of the chapter, we create a fabric canvas
    let layer = self.props.layer
    let tmpcanvas = self.props.canvas

    // We get back the previously created HTML canvas in our render() method
    let canvas = new fabric.Canvas("canvas-timeline" + layer.pk);
    document.getElementById("canvas-timeline" + layer.pk).fabric = canvas

    // We set the height and width to x2 with backstoreOnly to false, this allow us to show a 640px canvas into a 320px div
    let h = document.getElementById("canvas-container" + layer.pk).clientHeight;
    let w = document.getElementById("canvas-container" + layer.pk).clientWidth;

    canvas.setHeight(h, { backstoreOnly: false });
    canvas.setWidth(w, { backstoreOnly: false });
    
    // Canvas general configuration
    canvas.allowTouchScrolling = true
    canvas.preserveObjectStacking = true

    canvas.selection = false

    // We then load the fabric json in our canvas if we have it

    let jsonValue = JSON.stringify(tmpcanvas.toJSON(['layer', 'canMoveOutside']))

    //let json = layer.json;
    if (jsonValue !== null && jsonValue !== "") {
        canvas = canvas.loadFromJSON(jsonValue, function () {

            canvas.getObjects().forEach(obj => {
                obj.evented = false
                obj.selectable = false
            })
        });
    }

    // 640 is the height of the preview
    let zoom = 1 / (640 / h)
    canvas.setZoom(zoom)

    //Our temp array is set in the state
    self.setState({
        timelineCanvas: canvas
    })

};

const updateImageCanvas = async function (self) {

    // Foreach layer of the chapter, we create a fabric canvas
    let layer = self.props.layer
    let tmpcanvas = self.props.canvas

    // We get back the previously created HTML canvas in our render() method
    let canvas = document.getElementById("canvas-timeline" + layer.pk).fabric

    if (canvas) {
        let jsonValue = JSON.stringify(tmpcanvas.toJSON(['layer', 'canMoveOutside']))

        //let json = layer.json;
        if (jsonValue !== null && jsonValue !== "") {
            canvas = canvas.loadFromJSON(jsonValue, function () {
                canvas.getObjects().forEach(obj => {
                    obj.evented = false
                    obj.selectable = false
                })
            });
        }
    }
};