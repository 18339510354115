import {apiClient} from "./AxiosService"

class TriggerTypeService {

    static async create(name, identifier) {
        return apiClient
        .post(
            "trigger-type/",
            { name, identifier },
        ).then(response => {
            return response.data
        })
        .catch(error => {
            throw error
        });
    }

    static async delete(id) {
        return apiClient
        .delete(
            `trigger-type/${id}/`,
            { },
        ).then(response => {
            return response.data
        })
        .catch(error => {
            throw error
        });
    }

    static async get() {
        return apiClient
        .get(`trigger-type/`)
        .then(response => {
            return response.data
        })
        .catch(error => {
            throw error
        });
    }

}

export default TriggerTypeService;
