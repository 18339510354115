import React from "react";
import ReactDOM from "react-dom";
import Account from "./Pages/Account";
import Login from "./Pages/Login";
import Logout from "./Pages/Logout";
import ViewStoryPage from "./Pages/ViewStoryPage";
import ViewTimelinePage from "./Pages/ViewTimelinePage";
import ViewChapterPage from "./Pages/ViewChapterPage";

import NotFound from "./Pages/NotFound";
import AxiosService from "./Api/AxiosService";

import { CookiesProvider } from "react-cookie";
import registerServiceWorker from "./registerServiceWorker";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import CacheBuster from "./Components/CacheBuster/CacheBuster";
import ProfilePage from "./Pages/ProfilePage";

const Index = () => (
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        // You can decide how and when you want to force reload
        refreshCacheAndReload();
      }
      return (
        <CookiesProvider>
          <Router>
            <Switch>
              <Route exact path="/" component={Account} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <PrivateRoute exact path="/profile" component={ProfilePage} />
              <PrivateRoute exact path="/editor/timeline/:id" component={ViewTimelinePage} />
              <PrivateRoute exact path="/editor/chapter/:id" component={ViewChapterPage} />
              <PrivateRoute exact path="/story/:id" component={ViewStoryPage} />
              <Route component={NotFound} />
            </Switch>
          </Router>
          <ToastContainer />
        </CookiesProvider>
      )
    }}
  </CacheBuster>


);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      AxiosService.auth.isLoggedIn() ?
        (
          <Component {...props} />
        ) :

        (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
    }
  />
);

ReactDOM.render(
  <Index />, document.getElementById("root")
);

registerServiceWorker();
