import { apiClient } from "./AxiosService"

class MediaService {

    static async get() {
        return apiClient
            .get(`media/`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error
            });
    }

    static async post(file, folderId) {

        var formData = new FormData();
        formData.append('file', file);
        formData.append('folder', folderId);

        return apiClient
            .post(
                "media/",
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error
            });
    }

    static async delete(id) {
        
        return apiClient
            .delete(`media/${id}/`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error
            });
    }

}

export default MediaService;
