import React from 'react';
import LayerTimeline from './LayerTimeline'
import LayerTriggers from './LayerTriggers'

export default class LayerRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollRatioEditionMode: false,
            nameEditionMode: false
        }
    }

    render() {

        return (
            <div
                style={{
                    height: "50px",
                    display: 'grid',
                    gridTemplateColumns: '0.4fr 1.6fr'
                }}
            >

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: '15px',
                        borderRight: "1px solid #232323",
                        borderBottom: "1px solid #232323",
                    }}
                >
                    <div
                        style={{
                            width: "40%",
                        }}
                    >
                        {
                            this.state.nameEditionMode === true &&
                            <input
                                autoFocus
                                type="text"
                                id="name"
                                defaultValue={this.props.layer.name}
                                onBlur={(e) => this._onBlurName(e, this.props.layer)}
                                onKeyUp={(e) => this._onKeyUp(e)}
                                className="form-control"
                                style={{
                                    padding: "0",
                                    textAlign: "center",
                                    height: "100%",
                                    backgroundColor: "transparent",
                                    color: "white",
                                    border: "none"
                                }}
                            />
                        }
                        {
                            this.state.nameEditionMode === false &&
                            <h6
                                style={{
                                    margin: "0",
                                    padding: "0",
                                    verticalAlign: "middle",
                                    display: "table-cell",
                                    cursor: "pointer",
                                    color: (this.props.selectedLayer === this.props.layer) ? "#eec165" : "white",
                                }}
                                onDoubleClick={() => this._onEditName()}
                                onClick={() => this.props.onSelectLayer(this.props.layer)}
                            >
                                {this.props.layer.name}
                            </h6>
                        }

                    </div>

                    <div
                        style={{
                            width: "20%",
                            cursor: "pointer"
                        }}
                    >
                        <i
                            className={this.getFAIcon()}
                            aria-hidden="true"
                            style={{
                                color: '#fff',
                                opacity: this.getFaIconOpacity()
                            }}
                            onClick={() => this._onChangeOpacity()}
                        />
                    </div>

                    <i className={this.getLockIcon()} style={{ width: "20%", cursor: "pointer", color: '#fff' }} aria-hidden="true"
                        onClick={() => this._onChangeLayerLock()}></i>

                    <div
                        style={{
                            color: "white",
                            width: "20%",
                        }}
                    >

                        {
                            this.state.scrollRatioEditionMode === true &&
                            <input
                                autoFocus
                                type="number"
                                id="scrollRatio"
                                defaultValue={this.props.layer.scrollRatio}
                                min="0"
                                max="10"
                                step="0.1"
                                onBlur={(e) => this._onBlurScrollRatio(e, this.props.layer)}
                                onKeyUp={(e) => this._onKeyUp(e)}
                                className="form-control"
                                style={{
                                    padding: "0",
                                    textAlign: "center",
                                    height: "100%",
                                    backgroundColor: "transparent",
                                    color: "white",
                                    border: "none"
                                }}
                            />
                        }
                        {
                            this.state.scrollRatioEditionMode === false &&
                            <div
                                onClick={(e) =>
                                    this._onEditScrollRatio()
                                }
                            >
                                {this.props.layer.scrollRatio}
                            </div>
                        }

                    </div>

                    <div
                        style={{
                            color: "white",
                            width: "20%",
                            cursor: "pointer"
                        }}
                    >
                        <i className="fa fa-trash" onClick={() => this._onDeletePressed()} aria-hidden="true"></i>
                    </div>
                </div>

                {/* Timeline */}
                <div>
                    <div style={{ width: "100%", height: "40px" }}>
                        <LayerTimeline
                            layer={this.props.layer}
                            canvas={this.props.canvas}
                            //key={this.props.key}
                            onScrollTimeline={this.props.onScrollTimeline}
                            wheelDelta={this.props.wheelDelta}
                        />
                    </div>
                    <div style={{ width: "100%", height: "10px" }}>
                        <LayerTriggers
                            onRef={ref => { this.layerTriggers = ref }}
                            layer={this.props.layer}
                            //key={this.props.key}
                            onScrollTimeline={this.props.onScrollTimeline}
                            wheelDelta={this.props.wheelDelta}
                        />
                    </div>
                </div>

            </div>

        )
    }

    async componentDidMount() {
        this.props.onRef(this)

    }

    componentDidUpdate() {

    }

    _onEditScrollRatio() {
        this.setState({
            scrollRatioEditionMode: true
        })
    }

    triggersPromise() {
        return this.layerTriggers.triggersData()
    }

    _onBlurScrollRatio(event, layer) {

        this.setState({
            scrollRatioEditionMode: false
        })

        const newScrollRatio = event.currentTarget.valueAsNumber
        this.props.onChangeScrollRatio(layer, newScrollRatio)
    }

    _onBlurName(event, layer) {
        this.setState({
            nameEditionMode: false
        })

        const newName = event.currentTarget.value
        this.props.onChangeName(layer, newName)
    }

    _onEditName() {
        this.setState({
            nameEditionMode: true
        })
    }

    _onKeyUp(e) {
        if (e.keyCode === 13) {
            e.target.blur()
        }
    }

    _onDeletePressed() {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce layer ? Cette opération est irréversible")) {
            this.props.onDeleteLayer(this.props.layer)
        }
    }

    _onChangeLayerLock = () => {
        const key = "layer_" + this.props.layer.pk + "_lock"
        const value = localStorage.getItem(key)
        let newValue = true
        if (value != null)
            newValue = !(value === 'true')
        localStorage.setItem(key, newValue)
        this.props.onChangeLock(this.props.layer.pk, newValue)
        this.forceUpdate()
    }

    _onChangeOpacity() {

        let key = "layer_" + this.props.layer.pk + "_opacity"
        //console.log("key is : " + key)

        let item = localStorage.getItem(key)
        //console.log("value is : " + item)

        let newValue = -1
        if (item === null || item === "1") {
            newValue = "0.5"

        } else if (item === "0.5") {
            newValue = "0"
        } else if (item === "0") {
            newValue = "1"
        }
        //console.log("newValue is : " + newValue)
        localStorage.setItem(key, newValue)

        this.props.onChangeOpacity()
        this.forceUpdate()
    }

    getFAIcon() {
        let key = "layer_" + this.props.layer.pk + "_opacity"

        let item = localStorage.getItem(key)

        if (item === null || item === "1") {
            return "fa fa-eye"
        } else if (item === "0.5") {
            return "fa fa-eye"
        } else if (item === "0") {
            return "fa fa-eye-slash"
        }
    }

    getFaIconOpacity() {
        let key = "layer_" + this.props.layer.pk + "_opacity"

        let item = localStorage.getItem(key)

        if (item === "0.5") {
            console.log("0.5")
            return "0.5"
        }
        else return "1"
    }

    getLockIcon = () => {
        const key = "layer_" + this.props.layer.pk + "_lock"
        const value = localStorage.getItem(key)
        let icon = 'fa fa-unlock-alt'
        if (value != null && value === 'true') {
            icon = 'fa fa-lock'
        }
        return icon
    }
}
