import React, { Component } from "react";
import { Button } from 'reactstrap';

class ObjectConfiguration extends Component {
  constructor(props) {
    super(props);

    this._fixeObject = this._fixeObject.bind(this);
    this._deleteObject = this._deleteObject.bind(this);
    this._canMouveOutside = this._canMouveOutside.bind(this);
  }

  render() {
    //console.log(this.props.currentObject)
    if (this.props.currentObject != null) {
      return (

        <div
          className=""
          style={{
            height: "40px",
            display: 'flex',
            alignItems: "center",
            alignContent: "center"
          }}
        >

          <i className="fa fa-cogs" aria-hidden="true"></i>

          <div style={{
            paddingLeft: '15px',
          }}>
            <div className="custom-control custom-checkbox">
              <input
                ref="fixed"
                className="custom-control-input"
                id="fix-object"
                type="checkbox"
                onChange={obj => this._fixeObject(this.props.currentObject)}
              />
              <label className="custom-control-label" htmlFor="fix-object">
                Fixer
            </label>
            </div>
          </div>

          <div style={{
            paddingLeft: '20px',
          }}>
            <div className="custom-control custom-checkbox">
              <input
                ref="moveOutside"
                className="custom-control-input"
                id="over-limit"
                type="checkbox"
                onChange={() => this._canMouveOutside(this.props.currentObject)}
              />
              <label className="custom-control-label" htmlFor="over-limit">
                Peut dépasser les limites
            </label>
            </div>
          </div>

          <div
            style={{
              paddingLeft: '20px',
            }}
          >
            <Button
              size="sm"
              onClick={() => this._sendToBack(this.props.currentObject)}
              color="primary"
              title="Envoyer au fond"
            >
              <i className="fa fa-angle-double-up" aria-hidden="true"></i>
            </Button>
          </div>

          <div
            style={{
              paddingLeft: '5px',
            }}
          >
            <Button
              size="sm"
              color="primary"
              onClick={() => this._sendBackwards(this.props.currentObject)}
              title="Reculer"
            >
              <i className="fa fa-angle-up" aria-hidden="true"></i>
            </Button>
          </div>

          <div
            style={{
              paddingLeft: '10px',
            }}
          >
            <Button
              size="sm"
              color="primary"
              onClick={() => this._bringForward(this.props.currentObject)}
              title="Avancer"
            >
              <i className="fa fa-angle-down" aria-hidden="true"></i>
            </Button>
          </div>

          <div
            style={{
              paddingLeft: '5px',
            }}
          >
            <Button
              size="sm"
              onClick={() => this._bringToFront(this.props.currentObject)}
              color="primary"
              title="Envoyer devant"
            >
              <i className="fa fa-angle-double-down" aria-hidden="true"></i>
            </Button>
          </div>

          <div
            style={{
              paddingLeft: '20px',
            }}
          >
            <Button
              color="danger"
              onClick={canvas => this._deleteObject(this.props.selectedCanvas)}
              size="sm"
            >
              Supprimer l'image
            </Button>
          </div>
        </div>
      );
    }
    else {
      return (
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}><i className="fa fa-cogs" aria-hidden="true" title="Veuillez sélectionner un objet dans la zone de travail"></i></div>
      );
    }
  }

  _bringToFront = obj => {
    obj.target.bringToFront()
    this.props.onSaveNeeded()
  }

  _bringForward = obj => {
    obj.target.bringForward()
    this.props.onSaveNeeded()
  }

  _sendToBack = obj => {
    obj.target.sendToBack()
    this.props.onSaveNeeded()
  }

  _sendBackwards = obj => {
    obj.target.sendBackwards()
    this.props.onSaveNeeded()
  }

  _canMouveOutside(obj) {
    obj.target.canMoveOutside = this.refs.moveOutside.checked;
    this.props.onSaveNeeded()
  }

  _fixeObject(obj) {
    obj.target.lockMovementX = this.refs.fixed.checked;
    obj.target.lockMovementY = this.refs.fixed.checked;
    this.props.onSaveNeeded()
  }

  _deleteObject(canvas) {
    console.log(canvas)
    canvas.remove(canvas.getActiveObject())
    this.props.onSaveNeeded()
  }

  componentDidUpdate() {
    if (this.props.currentObject != null) {
      this.refs.fixed.checked = this.props.currentObject.target.lockMovementX;
      this.refs.moveOutside.checked = this.props.currentObject.target.canMoveOutside;
    }
  }

}

export default ObjectConfiguration;
