import { apiClient} from "./AxiosService"

class FolderService {

    static async get(type) {
        return apiClient
            .get(`folder/?type=${type}`)
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error
            });
    }

    static async create(parentFolder, name, type) {
        return apiClient
            .post(
                `folder/`,
                { parentFolder, name, type },
                )
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error
            });
    }
    
    static async delete(id) {
        
        return apiClient
            .delete(`folder/${id}/`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error
            });
    }
    

}

export default FolderService;
