import React from 'react'
import { Button } from 'reactstrap'

export default class AddLayerRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div
                className=""
                style={{
                    height: "50px",
                    display: 'grid',
                    gridTemplateColumns: '0.4fr 1.6fr',
                }
                }
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: '15px',
                        borderBottom: "1px solid #232323"
                    }}
                >
                    <div
                        style={{
                            width: "40%",
                        }}
                    >
                        <Button
                            size="sm"
                            color="primary"
                            onClick={(id, json) => this.props.onCreateLayer()}
                        >
                            Nouveau layer
                        </Button>
                    </div>
                </div>


                <div
                    style={{
                        backgroundColor: "#333",
                        borderBottom: "1px solid #232323"
                    }}
                >

                </div>
            </div >
        )
    }

    async componentDidMount() {

    }

    componentDidUpdate() {

    }

}
