import { withCookies, Cookies } from 'react-cookie';
import { apiClient } from './AxiosService';
import jwt_decode from 'jwt-decode';

class LoginService {
    // Registering a new user
    static async register(mail, user, password) {
        return 1;
    }

    // Login to get a JWT Token
    static async login(email, password) {

        return apiClient
            .post('api-token-auth/', {
                'api-key': process.env.REACT_APP_API_KEY,
                email,
                password
            })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error
            });
    }

    static async updateProfile(password) {
        let cookie = new Cookies();
        let token = cookie.get('jwt');
        const decoded = jwt_decode(token);
        return await apiClient.patch(`user/${decoded.user_id}/`, { password })
            .catch(error => { throw error });
    }

    // Verify current JWT Token 
    static async isLoggedIn() {
        let cookie = new Cookies();
        let token = cookie.get('jwt');

        if (token === undefined) {
            return false;
        }

        return apiClient
            .post('api-token-verify/',
                { 'token': token },
            )
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error
            });
    }

    // Get current user infos
    static async infos() {
        return apiClient
            .get('user/infos/')
            .then(response => {
                return response.data
            })
            .catch(error => {
                throw error
            });
    }

}

export default withCookies(LoginService);
