import axios from 'axios'
import { Cookies } from "react-cookie";

import LoginService from './LoginService'
import StoryService from './StoryService';
import LayerService from './LayerService';
import SoundLayerService from './SoundLayerService';
import ChapterService from './ChapterService';
import TimelineService from './TimelineService';
import MediaService from './MediaService';
import FolderService from './FolderService';
import TriggerTypeService from './TypeTriggerService';


let cookie = new Cookies();
let token = cookie.get("jwt");

// static getCurrentUserId(jwt) {
//     var payload = jwtDecode(jwt);
//     if (payload != null) {
//         return payload.user_id;
//     }
//     return null;
// }

// static getApplicationId(jwt) {
//     var payload = jwtDecode(jwt);
//     if (payload != null) {
//         return payload.application_id;
//     }
//     return null;
// }

export let apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`
        }
})

//Now set up the routes.  We are going to export a default object with keys that keep our API routes organized.  For example, all of the auth routes live in the Auth object

export default {
    auth: {
        async login(email, password) {

            let data = await LoginService.login(email, password)
            
            if (data.token !== undefined) {
                cookie.set("jwt", data.token)
                token = data.token
    
                apiClient = axios.create({
                    baseURL: process.env.REACT_APP_API_BASE_URL,
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `JWT ${token}`
                        }
                })
            }
            
            return data
        },
        async isLoggedIn() {
            return await LoginService.isLoggedIn()
        },
        async infos(){
            return await LoginService.infos()
        },
        async updateProfile(password){
            return await LoginService.updateProfile(password);
        },
        logout(){
            if (cookie.get('jwt')) {
                cookie.remove('jwt')
            }
            return true
        }
    }, 
    story: {
        async get(id){
            return (id === undefined) ? await StoryService.get() : await StoryService.retrieve(id)
        },
        async create(name){
            return await StoryService.create(name)
        },
        async delete(id){
            return await StoryService.delete(id)
        },
        async publish(id, published){
            return await StoryService.publish(id, published)
        },
        async updateVersion(id, version){
            return await StoryService.updateVersion(id, version)
        },
        async rename(id, name){
            return await StoryService.rename(id, name)
        }
    },
    layer: {
        async create(name, scrollRatio, chapterId, main){
            return await LayerService.create(name, scrollRatio, chapterId, main)
        },
        async delete(id){
            return await LayerService.delete(id)
        },
        async updateData(layer, json, svg, jsonTriggers, scrollRatio, name){
            return await LayerService.updateData(layer, json, svg, jsonTriggers, scrollRatio, name)
        }
    },
    soundLayer: {
        async create(name, chapterId){
            return await SoundLayerService.create(name, chapterId)
        },
        async delete(id){
            return await SoundLayerService.delete(id)
        },
        async updateData(soundLayer, json){
            return await SoundLayerService.updateData(soundLayer, json)
        },
    },
    chapter: {
        async get(id){
            return await ChapterService.retrieve(id)
        },
        async getByTimeline(timelineId){
            return await ChapterService.getByTimeline(timelineId)
        },
        async create(timelineId, name, order){
            return await ChapterService.create(timelineId, name, order)
        },
        async saveJSON(id, json){
            return await ChapterService.saveJSON(id, json)
        },
        async delete(id){
            return await ChapterService.delete(id)
        },
        async publish(id, published){
            return await ChapterService.publish(id, published)
        },
        async rename(id, name){
            return await ChapterService.rename(id, name)
        },
        async changeImage(id, image){
            return await ChapterService.changeImage(id, image)
        },
    },
    timeline: {
        async get(id){
            return await TimelineService.retrieve(id)
        },
        async getByStory(storyId){
            return await TimelineService.getByStory(storyId)
        },
        async create(storyId, name, order){
            return await TimelineService.create(storyId, name, order)
        },
        async delete(id){
            return await TimelineService.delete(id)
        },
        async rename(id, name){
            return await TimelineService.rename(id, name)
        }
    },
    media: {
        async post(file, folderId){
            return await MediaService.post(file, folderId)
        },
        getMediaUrl(file) {
            return process.env.REACT_APP_API_BASE_URL + file
        },
        async get(){
            return await MediaService.get()
        },
        async delete(id){
            return await MediaService.delete(id)
        }
    },
    folder: {
        async get(type){
            return await FolderService.get(type)
        },
        async create(parentFolderId, name, type){
            return await FolderService.create(parentFolderId, name, type)
        },
        async delete(id){
            return await FolderService.delete(id)
        }
    },
    triggerType: {
        async get(){
            return await TriggerTypeService.get()
        },
        async create(name, identifier){
            return await TriggerTypeService.create(name, identifier)
        },
        async delete(id){
            return await TriggerTypeService.delete(id)
        }
    }
}
