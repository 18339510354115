import React from 'react';

import AxiosService from '../../Api/AxiosService'

export default class FriseTriggers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isVisible: false,
        }
    }

    render() {
        return (
            <>
                {/* Folders and images if expanded */}
                <div className={'container dock-gallery ' + (this.props.isVisible ? 'dock-gallery-extended' : '')} style={{ height: '100vh', overflowY: 'scroll' }}>
                    <div className="row mb-2">
                        <div className="col-md-12">
                            <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={(id, json) => this._handleCreateTriggerType()}
                            >
                                +  Créer un effet
                            </button>
                        </div>


                        <div className="col-md-12 mt-2">
                            {/* Effects */}

                            {
                                this.props.trigger_types &&
                                this.props.trigger_types.map((trigger_type, i) => {

                                    return (
                                        <div key={i} style={{ marginBottom: '5px' }}>
                                            <img
                                                src={(trigger_type.image !== "") ? AxiosService.media.getMediaUrl(trigger_type.image) : require("../../assets/home/img/icon-placeholder.png")}
                                                alt={trigger_type.identifier}
                                                style={{
                                                    height: "30px",
                                                    cursor: 'grab'
                                                }}
                                                onDragStart={(e) =>
                                                    this._handleDragStart(e)
                                                }
                                                onDragEnd={(e) =>
                                                    this._handleDragEnd(e)
                                                }
                                                onClick={(e) =>
                                                    this._handleSimpleClick(trigger_type)
                                                }
                                            />

                                            {/* Effect name */}
                                            <span style={{color: '#fff'}}>&nbsp;{trigger_type.name}</span>

                                            {/* Delete button if trigger clicked */}
                                            {
                                                this.state.clickedTriggerType &&
                                                this.state.clickedTriggerType === trigger_type &&
                                                this.state.clickedTriggerType.owner != null &&
                                                <button
                                                    className="btn btn-delete"
                                                    onClick={(e) => this._handleDelete(trigger_type)}>
                                                    X
                                                    </button>
                                            }

                                        </div>
                                    )
                                })
                            }

                        </div>

                    </div>
                </div>


            </>

        )
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isVisible !== this.props.isVisible) {
            this.setState({ isVisible: nextProps.isVisible });
        }
    }

    async componentDidUpdate(previousProps, previousState) {
        if (this.state.extended !== this.props.extended) {
            this.setState({
                extended: this.props.extended
            })
        }


    }

    _handleExtend() {
        this.props.onExtend(!this.state.extended)
    }

    _handleDragStart(e) {
        e.target.classList.add("trigger_type_dragging")
    }

    _handleDragEnd(e) {
        e.target.classList.remove("trigger_type_dragging")
    }

    _handleSimpleClick(trigger_type) {
        this.setState({
            clickedTriggerType: trigger_type
        })
    }

    async _handleDelete(trigger_type) {
        await AxiosService.triggerType.delete(trigger_type.id)
        this.props.onUpdateTriggerType()
    }

    async _handleCreateTriggerType() {

        let triggerTypeName = prompt(
            "Veuillez entrer le nom de votre effet",
            ""
        );

        let triggerTypeIdentifier = prompt(
            "Veuillez entrer l'identifier de votre effet. Celui-ci doit être unique",
            ""
        );

        if (triggerTypeName == null || triggerTypeName === "" || triggerTypeIdentifier == null || triggerTypeIdentifier === "") {
            alert("Veuillez renseigner un nom et un identifier");
        } else {
            try {
                await AxiosService.triggerType.create(triggerTypeName, triggerTypeIdentifier)

                this.props.onUpdateTriggerType()
            } catch (e) {
                alert(e)
            }
        }

    }

    sendRequest(file, folder) {
        return new Promise((resolve, reject) => {

            resolve(AxiosService.media.post(file, folder.id))
        });
    }

}



