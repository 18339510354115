import {apiClient} from "./AxiosService"

class LayerService {

    static async create(name, scrollRatio, chapter, main=false) {
        return apiClient
        .post(
            "layer/",
            { name, scrollRatio, chapter, main },
        ).then(response => {
            return response.data
        })
        .catch(error => {
            throw error
        });
    }

    static async delete(id) {
        return apiClient
        .delete(
            `layer/${id}/`,
            { },
        ).then(response => {
            return response.data
        })
        .catch(error => {
            throw error
        });
    }

    static async get() {
    }

    static async updateData(layer, json, svg, jsonTriggers, scrollRatio, name) {
        return apiClient
        .patch(
            `layer/${layer.pk}/`,
            { json, svg, jsonTriggers, scrollRatio, name },
        ).then(response => {
            return response.data
        })
        .catch(error => {
            throw error
        });
    }

}

export default LayerService;
